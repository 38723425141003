import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';

const FitSizeGuide: React.FC = () => {
  const { formatMessage } = useFormat({ name: 'Guides' });
  return (
    <div className="my-32 grid grid-cols-1 gap-52 lg:grid-cols-8">
      <div className="col-span-2">
        <Typography fontFamily="NunitoSansBold" className="tpb-5 text-28">
          Fit Guide
        </Typography>

        <ul className="sticky top-[145px] font-NunitoSansSemiBold text-16">
          <li className="border-b py-10">
            <a href="#Top">Tops</a>
          </li>
          <li className="border-b py-10">
            <a href="#Sports-Top">Sport Tops</a>
          </li>
          <li className="border-b py-10">
            <a href="#Bottoms">Bottoms</a>
          </li>
          <li className="border-b py-10">
            <a href="#Accessories">Accessories</a>
          </li>
          <li className="border-b py-10">
            <a href="#Kids">Kids &amp; Baby</a>
          </li>
          <li className="py-10">
            <a href="#howToMeassure">How to measure</a>
          </li>
        </ul>
      </div>
      <div className="col-span-6">
        <Typography fontFamily="NunitoSansBold" className="pb-5 text-28" id="sizeGuides">
          Size Guide
        </Typography>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="tops">
          Tops
        </Typography>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-16" id="Jacket">
          Bomber Jacket (Inches)
        </Typography>

        <table className="mb-32 mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">5XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">6XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">7XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26 1/4</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38</td>
              <td className="px-6 py-4 font-NunitoSansRegular">41</td>
              <td className="px-6 py-4 font-NunitoSansRegular">44</td>
              <td className="px-6 py-4 font-NunitoSansRegular">47</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">57</td>
              <td className="px-6 py-4 font-NunitoSansRegular">61</td>
              <td className="px-6 py-4 font-NunitoSansRegular">65</td>
              <td className="px-6 py-4 font-NunitoSansRegular">69</td>
              <td className="px-6 py-4 font-NunitoSansRegular">73</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">47</td>
              <td className="px-6 py-4 font-NunitoSansRegular">51</td>
              <td className="px-6 py-4 font-NunitoSansRegular">55</td>
              <td className="px-6 py-4 font-NunitoSansRegular">59</td>
              <td className="px-6 py-4 font-NunitoSansRegular">63</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 5/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 7/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29.75</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">39 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40 1/4</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-16">
          Camisol (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">5XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">11</td>
              <td className="px-6 py-4 font-NunitoSansRegular">11 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">11 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">12</td>
              <td className="px-6 py-4 font-NunitoSansRegular">12 1/4</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38</td>
              <td className="px-6 py-4 font-NunitoSansRegular">42</td>
              <td className="px-6 py-4 font-NunitoSansRegular">44</td>
              <td className="px-6 py-4 font-NunitoSansRegular">46</td>
              <td className="px-6 py-4 font-NunitoSansRegular">48</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">39</td>
              <td className="px-6 py-4 font-NunitoSansRegular">41</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">45</td>
              <td className="px-6 py-4 font-NunitoSansRegular">47</td>
              <td className="px-6 py-4 font-NunitoSansRegular">49</td>
              <td className="px-6 py-4 font-NunitoSansRegular">51</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-16">
          Denim Jacket (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">5XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">6XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31 3/4</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 5/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 7/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">42 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">44 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">46</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">52</td>
              <td className="px-6 py-4 font-NunitoSansRegular">54 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">56 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">59 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">62</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">42 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">45</td>
              <td className="px-6 py-4 font-NunitoSansRegular">47 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">55 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">58</td>
              <td className="px-6 py-4 font-NunitoSansRegular">60 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-16">
          Extra Sweatshirt (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">5XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">6XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38</td>
              <td className="px-6 py-4 font-NunitoSansRegular">41</td>
              <td className="px-6 py-4 font-NunitoSansRegular">44</td>
              <td className="px-6 py-4 font-NunitoSansRegular">47</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">57</td>
              <td className="px-6 py-4 font-NunitoSansRegular">61</td>
              <td className="px-6 py-4 font-NunitoSansRegular">65</td>
              <td className="px-6 py-4 font-NunitoSansRegular">68</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">46</td>
              <td className="px-6 py-4 font-NunitoSansRegular">49</td>
              <td className="px-6 py-4 font-NunitoSansRegular">52</td>
              <td className="px-6 py-4 font-NunitoSansRegular">55</td>
              <td className="px-6 py-4 font-NunitoSansRegular">58</td>
              <td className="px-6 py-4 font-NunitoSansRegular">61</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">39 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">41</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-16">
          Long Sleeve Crew Tee (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">5XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">6XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">46</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">56</td>
              <td className="px-6 py-4 font-NunitoSansRegular">59</td>
              <td className="px-6 py-4 font-NunitoSansRegular">62</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">46</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">56</td>
              <td className="px-6 py-4 font-NunitoSansRegular">59</td>
              <td className="px-6 py-4 font-NunitoSansRegular">62</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">12</td>
              <td className="px-6 py-4 font-NunitoSansRegular">13</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10 1/2</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-16">
          Short Sleeve Tee (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">5XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">6XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">46</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">56</td>
              <td className="px-6 py-4 font-NunitoSansRegular">59</td>
              <td className="px-6 py-4 font-NunitoSansRegular">62</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">46</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">56</td>
              <td className="px-6 py-4 font-NunitoSansRegular">59</td>
              <td className="px-6 py-4 font-NunitoSansRegular">62</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">12</td>
              <td className="px-6 py-4 font-NunitoSansRegular">13</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">39</td>
              <td className="px-6 py-4 font-NunitoSansRegular">39 3/4</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-16">
          {formatMessage({ id: 'mens.shirt', defaultMessage: "Men's shirt (Inches)" })}
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">5XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">6XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24 1/4</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38</td>
              <td className="px-6 py-4 font-NunitoSansRegular">41</td>
              <td className="px-6 py-4 font-NunitoSansRegular">44</td>
              <td className="px-6 py-4 font-NunitoSansRegular">47</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">54</td>
              <td className="px-6 py-4 font-NunitoSansRegular">57</td>
              <td className="px-6 py-4 font-NunitoSansRegular">60</td>
              <td className="px-6 py-4 font-NunitoSansRegular">64</td>
              <td className="px-6 py-4 font-NunitoSansRegular">68</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38</td>
              <td className="px-6 py-4 font-NunitoSansRegular">41</td>
              <td className="px-6 py-4 font-NunitoSansRegular">44</td>
              <td className="px-6 py-4 font-NunitoSansRegular">47</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">54</td>
              <td className="px-6 py-4 font-NunitoSansRegular">57</td>
              <td className="px-6 py-4 font-NunitoSansRegular">60</td>
              <td className="px-6 py-4 font-NunitoSansRegular">64</td>
              <td className="px-6 py-4 font-NunitoSansRegular">68</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 5/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 7/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 3/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 5/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23 7/8</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="Sports-Top">
          Sport Tops
        </Typography>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-16">
          Sport Bra (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">US Size</td>
              <td className="px-6 py-4 font-NunitoSansRegular">0</td>
              <td className="px-6 py-4 font-NunitoSansRegular">2-4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6-8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10-12</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bust</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32 - 33in (81 - 84cm)</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33.5 - 34.5in (85 - 88cm)</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35 - 37in (89 - 94cm)</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38 - 41in (96.5 - 104cm)</td>
              <td className="px-6 py-4 font-NunitoSansRegular">42 - 43.5in (107 - 110.5cm)</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="Bottoms">
          Bottoms
        </Typography>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-16">
          Leggings (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Waist</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24.5-25.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26.5-27.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28.5-29.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31-32.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34-35</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">HIP</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34.5-35.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36.5-37.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38.5-39.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">41-42.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">44</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-16">
          {formatMessage({ id: 'mens.shirt.3.4', defaultMessage: "Men's 3/4 Length Leggings (Inches)" })}
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">US Size</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Waist</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28in (71cm)</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30in (76cm)</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32in (81cm)</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34in (86cm)</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36in (91.5cm)</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38in (96.5cm)</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="Accessories">
          Accessories
        </Typography>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-16">
          Slides
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular">Slide Size</th>
              <th className="px-6 py-4 font-NunitoSansRegular">36</th>
              <th className="px-6 py-4 font-NunitoSansRegular">37</th>
              <th className="px-6 py-4 font-NunitoSansRegular">38</th>
              <th className="px-6 py-4 font-NunitoSansRegular">39</th>
              <th className="px-6 py-4 font-NunitoSansRegular">40</th>
              <th className="px-6 py-4 font-NunitoSansRegular">41</th>
              <th className="px-6 py-4 font-NunitoSansRegular">42</th>
              <th className="px-6 py-4 font-NunitoSansRegular">43</th>
              <th className="px-6 py-4 font-NunitoSansRegular">44</th>
              <th className="px-6 py-4 font-NunitoSansRegular">45</th>
              <th className="px-6 py-4 font-NunitoSansRegular">46</th>
              <th className="px-6 py-4 font-NunitoSansRegular">47</th>
              <th className="px-6 py-4 font-NunitoSansRegular">48</th>
              <th className="px-6 py-4 font-NunitoSansRegular">49</th>
              <th className="px-6 py-4 font-NunitoSansRegular">50</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">
                {formatMessage({ id: 'womens.shoes', defaultMessage: "Women's Shoe Size" })}
              </td>
              <td className="px-6 py-4 font-NunitoSansRegular">5-5.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6-6.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7-7.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8-8.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9-9.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10-10.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">11-11.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">12-12.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">13-13.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14-14.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15-15.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16-16.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17-17.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18-18.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19-19.5</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">
                {formatMessage({ id: 'mens.shoes', defaultMessage: "Men's Shoe Size" })}
              </td>
              <td className="px-6 py-4 font-NunitoSansRegular">3-3.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">4-4.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">5-5.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6-6.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7-7.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8-8.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9-9.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10-10.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">11-11.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">12-12.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">13-13.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14-14.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15-15.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16-16.5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17-17.5</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="Kids">
          Kids
        </Typography>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-16">
          Onesie (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">3-6 MONTH</th>
              <th className="px-6 py-4 font-NunitoSansRegular">6-12 MONTHS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">12-18 MONTH</th>
              <th className="px-6 py-4 font-NunitoSansRegular">18-24 MONTH</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Back</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 1/4</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-16">
          Youth Tees (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular">&nbsp;</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">5XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">6XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">13 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">13 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">5 1/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">5 13/16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 3/16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">5 11/16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">2 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">2 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">2 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">2 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">2 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
              <td className="px-6 py-4 font-NunitoSansRegular">&nbsp;</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="howToMeassure">
          How to measure
        </Typography>

        <Typography fontFamily="NunitoSansSemiBold" className="py-5 text-16" id="howToMeassure">
          For accuracy have someone else take your measurements
        </Typography>

        <div className="my-10 flex gap-8">
          <Typography fontFamily="NunitoSansBold" className="text-16" id="howToMeassure">
            Chest:
          </Typography>
          <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
            Raise your arms slightly and measure around the fullest part of your chest.
          </Typography>
        </div>

        <div className="my-10 flex gap-8">
          <Typography fontFamily="NunitoSansBold" className="text-16" id="howToMeassure">
            Waist:
          </Typography>
          <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
            Measure is taken around the natural waistline. Ensure to keep the tape comfortably loose.
          </Typography>
        </div>

        <div className="my-10 flex gap-8">
          <Typography fontFamily="NunitoSansBold" className="text-16" id="howToMeassure">
            Hip:
          </Typography>
          <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
            Stand with heels together. Keep the tape straight and parallel to the floor and measure around the fullest
            part.
          </Typography>
        </div>
        <div className="my-10 flex gap-8">
          <Typography fontFamily="NunitoSansBold" className="text-16" id="howToMeassure">
            Bicep:
          </Typography>
          <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
            Wrap a tape measure around the fullest part of your upper arm.
          </Typography>
        </div>

        <div className="my-10 flex gap-8">
          <Typography fontFamily="NunitoSansBold" className="text-16" id="howToMeassure">
            Arm length:
          </Typography>
          <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
            With your arm bent at 90 degrees. Place your hand on your hip. Measure from the back of your neck to your
            shoulder, elbow, and wrist.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default FitSizeGuide;
