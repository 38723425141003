import React from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';

interface Image {
  fields: {
    file: {
      url: string;
    };
  };
}
export interface CardProps {
  image?: Image;
  subHeader: string;
  title: string;
  ecoParagraph: string;
  ctaLabel: string;
  ctaReference: string;
}

const Card: React.FC<CardProps> = ({ image, subHeader, title, ecoParagraph, ctaLabel, ctaReference }) => {
  return (
    <div className="margin flex w-full flex-col items-center">
      {image ? (
        <img
          src={image?.fields?.file?.url}
          alt="Component Image"
          className="h-auto max-h-[9rem] w-full lg:max-h-[14rem]"
        />
      ) : (
        <div className="h-240 w-full bg-[#E2F4E2]" />
      )}
      <div className="mt-15 flex w-full flex-col items-center">
        <h3 className="text-2xl font-semibold text-[#0D9D00]">
          <Typography fontFamily="NunitoSansSemiBold" fontSize={26}>
            {subHeader}
          </Typography>
        </h3>
        <Typography fontFamily="NunitoSansSemiBold" fontSize={26} className="text-center text-[#0D9D00]">
          {title}
        </Typography>
        <p className="mt-15 text-lg">
          <Typography className="text-center capitalize text-[#0D9D00]" fontFamily="NunitoSansBold" fontSize={12}>
            {ecoParagraph}
          </Typography>
        </p>
        <a href={ctaReference} className="mt-15 capitalize text-[#0D9D00] underline">
          <Typography className="text-center" fontFamily="NunitoSansBold" fontSize={12}>
            {ctaLabel}
          </Typography>
        </a>
      </div>
    </div>
  );
};

export default Card;
