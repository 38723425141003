import * as React from "react";

interface Props {
    title: string;
}

const TextTitle = ({title}: Props) => {
    if (!title) return <div></div>;

    return (
        <>
            <h1>{title}</h1>
        </>
    );
};

export default TextTitle;
