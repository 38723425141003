import React, { createContext, useCallback, useRef } from 'react';
import Header from 'components/commercetools-ui/organisms/header';
import { Market } from 'components/commercetools-ui/organisms/header/types';
import { MarketProvider } from 'context/market';
import useResizeObserver from 'helpers/hooks/useResizeObserver';
import { Category } from 'types/category';
import { formatCustomerGroup } from 'helpers/formatter';

const initialMarketState = {
  market: {} as Market,
  markets: [] as Market[],
  handleMarket: {} as (market: Market) => void,
};

interface Props {
  data: any;
  categories: Category[];
  customerGroup: any;
}

export const MarketContext = createContext(initialMarketState);

const HeaderTastic = ({ data, categories, customerGroup }: Props) => {
  const headerRef = useRef<HTMLDivElement>(null);

  const setPaddingTop = useCallback(() => {
    if (headerRef.current) {
      document.body.style.paddingTop = `${headerRef.current.clientHeight - 1}px`;
    }
  }, []);

  const removePaddingTop = useCallback(() => {
    document.body.style.paddingTop = '0px';
  }, []);

  const fields = data?.data?.dataSource?.fields;

  const formattedCustomerGroup = formatCustomerGroup(customerGroup?.obj?.key!);

  const filteredData = fields?.links?.filter((item: { metadata: { tags: any[] } }) =>
    item.metadata.tags.some((tag) => tag.sys.id === formattedCustomerGroup || tag.sys.id === 'allUsers'),
  );

  const formattedData = filteredData?.map((link: { fields: object }) => link.fields) || [];

  useResizeObserver(headerRef, setPaddingTop, removePaddingTop);

  return (
    <MarketProvider>
      <div id="header-container" ref={headerRef}>
        <Header
          navLinks={formattedData}
          categories={categories}
          customerGroup={customerGroup}
          logo={fields?.logo?.fields?.file?.url}
          src={fields?.logo?.fields?.file?.url}
          icon={fields?.icon[0]?.fields?.file?.url}
          logoLink={data?.logoLink}
          logoMobile={data?.logoMobile}
          logoLinkMobile={data?.logoLinkMobile}
          emptyCartTitle={data?.emptyCartTitle}
          emptyCartSubtitle={data?.emptyCartSubtitle}
          emptyCartImage={data?.emptyCartImage}
        />
      </div>
    </MarketProvider>
  );
};

export default HeaderTastic;
