import React from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';
import Tile, { TileProps } from 'components/commercetools-ui/organisms/content/tile';

export interface GridRow {
  icon: string;
  leftText: string;
  rightText: string;
}

export interface TextTileProps {
  icon: string;
  mainText: string;
  ctaText: string;
  ctaReference: string;
  resultsEco: GridRow[];
  tileContent: TileProps;
}

const TextTile: React.FC<TextTileProps> = ({ icon, mainText, ctaText, ctaReference, resultsEco, tileContent }) => {
  return (
    <div className="mt-52 flex flex-wrap items-center gap-16 lg:flex-nowrap">
      <div className="mx-0 w-full text-[#0E9C00] lg:mx-52 lg:w-1/2">
        <div className="flex flex-col gap-4">
          <img src={icon} alt="Icon" className="h-40 w-40" />
          <span className="text-lg font-bold">
            <Typography fontFamily="NunitoSansBold" fontSize={28}>
              {mainText}
            </Typography>
          </span>
        </div>
        <a href={ctaReference}>
          <button className="mt-10 rounded-md border-2 border-[#E2F4E2] bg-white px-12 py-6 font-PoppinsSemiBold text-14 text-[#0E9C00]">
            {ctaText}
          </button>
        </a>
        <div className="mt-36 lg:mt-90">
          {resultsEco?.map((row, index) => (
            <div
              key={index}
              className={`${
                index !== resultsEco.length - 1 ? 'mb-4 border-b border-dashed border-[#0E9C00] pb-4' : ''
              } flex items-center justify-between`}
            >
              <div className="flex items-center gap-4">
                <img src={row.icon} alt="Row Icon" className="h-30 w-30" />
                <span>
                  <Typography fontFamily="IBMPlexMonoRegular" fontSize={28}>
                    {row.leftText}
                  </Typography>
                </span>
              </div>
              <span>
                <Typography fontFamily="NunitoSansBold" fontSize={12}>
                  {row.rightText}
                </Typography>
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full lg:w-1/2">
        <Tile
          image={tileContent.image}
          title={tileContent.title}
          subtitle={tileContent.subtitle}
          ctaLabel={tileContent.ctaLabel}
          ctaReference={tileContent.ctaReference}
          className="mb-8 mt-52 h-[296px] md:mb-0 md:h-[532px] lg:mt-0 lg:h-[668px]"
          alignment="left"
        />
      </div>
    </div>
  );
};

export default TextTile;
