import React from 'react';
import Hero, { HeroProps } from 'components/commercetools-ui/organisms/content/hero';

interface Props {
  data: any;
}

const HeroTastic = ({ data }: Props) => {
  const fields = data.data.dataSource.fields;

  return (
    <Hero
      image={fields.image.fields.file.url}
      title={fields.title}
      subtitle={fields.subtitle}
      ctaLabel={fields.ctaLabel}
      ctaReference={fields.ctaReference}
    />
  );
};

export default HeroTastic;
