import React, { FC, useEffect, useState } from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import useClassNames from 'helpers/hooks/useClassNames';

export interface Props {
  link: string;
  linkName: string;
}

const HeaderNavigationButtonDesktop: FC<Props> = ({ link, linkName }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const navLinkClassNames = useClassNames([
    'flex border-primary-regular py-4 cursor-pointer relative hover:border-b-2',
  ]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  if (!isLoaded) return <></>;

  return (
    <div className="h-52 px-10 py-12">
      <Link link={link} title={linkName} className={navLinkClassNames}>
        <Typography fontFamily="NunitoSansBold" fontSize={13}>
          {linkName}
        </Typography>
      </Link>
    </div>
  );
};
export default HeaderNavigationButtonDesktop;
