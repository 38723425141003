import React, { FC, useState } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import Button from 'components/commercetools-ui/atoms/button';
import Drawer from 'components/commercetools-ui/atoms/drawer';
import { useFormat } from 'helpers/hooks/useFormat';
import { Category } from 'types/category';
import { Reference } from 'types/reference';
import { NextFrontasticImage } from 'frontastic/lib/image';
import MobileMenu from './content/mobile-menu';
import MobileMenuFooter from './content/mobile-menu-footer';
import MobileMenuHeader from './content/mobile-menu-header';

export interface Props {
  logo: NextFrontasticImage;
  logoLink: Reference;
  links: Category[];
  src: string;
}

const HeaderNavigationMobile: FC<Props> = ({ links, logo, logoLink, src }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const [categoriesNavigator, setCategoriesNavigator] = useState<Category[]>([]);
  const [showMenu, setShowMenu] = useState(false);

  const showHeaderMenu = () => {
    setShowMenu(true);
  };

  const hideHeaderMenu = () => {
    setShowMenu(false);
    setCategoriesNavigator([]);
  };

  const removeCategory = () => {
    setCategoriesNavigator((array) => array.slice(0, -1));
  };

  const insertCategory = (category: Category) => {
    setCategoriesNavigator((array) => [...array, category]);
  };

  return (
    <div className="flex items-stretch xl:hidden">
      <Button
        variant="ghost"
        size="icon"
        onClick={showHeaderMenu}
        title={formatMessage({ id: 'header.menu.open', defaultMessage: 'Open side menu' })}
        className="mr-8"
      >
        <Bars3Icon className="w-30 text-secondary-regular lg:w-44" />
      </Button>

      <Drawer
        isOpen={showMenu}
        direction="left"
        className="w-4/5 border border-neutral-400 bg-neutral-100"
        onClose={hideHeaderMenu}
      >
        <div className="overflow-y-scroll">
          <MobileMenuHeader
            src={src}
            categories={categoriesNavigator}
            hideHeaderMenu={hideHeaderMenu}
            logo={logo}
            logoLink={logoLink}
            onArrowClick={removeCategory}
          />

          <MobileMenu
            links={links}
            hideHeaderMenu={hideHeaderMenu}
            categoriesNavigator={categoriesNavigator}
            insertCategory={insertCategory}
          />

          {categoriesNavigator.length <= 0 && (
            <MobileMenuFooter showMenu={showMenu} hideHeaderMenu={hideHeaderMenu} insertCategory={insertCategory} />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default HeaderNavigationMobile;
