import React from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';

export interface Title {
  title: string;
  subtitle: string;
}

export interface Props {
  title: Title;
}

const Title: React.FC<Props> = ({ title }) => (
  <div className="mt-52 flex flex-col items-center md:mb-30 lg:mb-60">
    <Typography className="text-center text-24 md:text-36">{title.title}</Typography>
    <Typography fontSize={14}>{title.subtitle}</Typography>
  </div>
);

export default Title;
