import { useCallback, createElement } from 'react';
import WaterIcon from 'components/icons/water-icon';
import EmissionIcon from 'components/icons/emission-icon';
import LandUse from 'components/icons/land-use';
import { Variant } from '@commercetools/frontend-domain-types/product/Variant';
import { LineItem } from '@commercetools/frontend-domain-types/cart/LineItem';
import { IconText } from 'components/commercetools-ui/organisms/greenland-score';

const useImpacts = (): UseImpactsReturn => {
  const getImpactByProduct = useCallback((variant: Variant) => {
    const attributes = variant?.attributes;
    const impactEntries = Object.entries(attributes || [])?.filter(([key, val]) => key.startsWith('impacts'));
    const impactsObject = Object.fromEntries(impactEntries);
    const { 'impacts-ghg': ghg, 'impacts-land-usage': landUsage, 'impacts-water-usage': waterUsage } = impactsObject;

    const setIconData = [
      { icon: createElement(EmissionIcon, null, null), text: `${(ghg || 0) * 100}`, units: '%' },
      { icon: createElement(WaterIcon, null, null), text: `${(waterUsage || 0) * 100}`, units: '%' },
      { icon: createElement(LandUse, null, null), text: `${(landUsage || 0) * 100}`, units: '%' },
    ];
    return setIconData;
  }, []);

  const getTotalImpactByCart = useCallback((lineItems: LineItem[]) => {
    const summaryImpacts: any = [];
    lineItems.forEach((lineItem) => {
      const count = lineItem?.count;
      const attributes = lineItem.variant?.attributes;
      const impactEntries = Object.entries(attributes || [])
        ?.filter(([key, val]) => key.startsWith('impacts'))
        ?.map(([key, val]) => [key, val * (count || 0)]);
      const impactsObject = Object.fromEntries(impactEntries);
      summaryImpacts.push(impactsObject);
    });
    const summaryValues = summaryImpacts?.reduce(
      (acc: any, current: any) => {
        const { 'impacts-ghg': ghg, 'impacts-land-usage': landUsage, 'impacts-water-usage': waterUsage } = current;
        return {
          ghg: !isNaN(ghg) ? acc['ghg'] + ghg : acc['ghg'],
          landUsage: !isNaN(landUsage) ? acc['landUsage'] + landUsage : acc['landUsage'],
          waterUsage: !isNaN(waterUsage) ? acc['waterUsage'] + waterUsage : acc['waterUsage'],
        };
      },
      {
        ghg: 0,
        landUsage: 0,
        waterUsage: 0,
      },
    );

    const setIconData = [
      { icon: createElement(EmissionIcon, null, null), text: summaryValues.ghg, units: 'T CO₂-eq' },
      { icon: createElement(WaterIcon, null, null), text: summaryValues.waterUsage, units: 'm³' },
      { icon: createElement(LandUse, null, null), text: summaryValues.landUsage, units: 'm²' },
    ];
    return setIconData;
  }, []);

  return { getImpactByProduct, getTotalImpactByCart };
};

export default useImpacts;

export interface UseImpactsReturn {
  getImpactByProduct: (variant: Variant) => IconText[];
  getTotalImpactByCart: (lineItems: LineItem[]) => IconText[];
}
