import React from 'react';
import { TextTitle } from '@loomstateorg/shared-frontend';

interface Props {
  data?: any;
}

const TextTitleTastic = ({ data }: Props) => {
  return <TextTitle title={data.title} />;
};

export default TextTitleTastic;
