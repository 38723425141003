import styles from './ImpactsTastic.module.css';

interface Props {
  data: { fullWidth: boolean; textAlign: 'center' | 'left' | 'right' | 'justify' };
}

const ImpactsTastic = ({ data: { fullWidth = false, textAlign = 'left', ...data } }: Props) => {
  return (
    <div id="impacts-main" className="w-full grow">
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(12, minmax(0, 1fr))', columnGap: '20px' }}>
        <div className="container mx-auto" style={{ gridColumn: '2 / span 10' }}>
          <div className={styles.introText}>
            Loomstate considers the full impact of materials and processes when we design a product - from the farm
            level to the finished garment. We report the annual environmental & social impacts for each of our private
            label clients based on their respective production volumes. What follows are some of our 2021 impacts across
            all clients. *Calculations are done using a combination of the Textile Exchange Uptake Calculator, Organic
            Cotton Market Report 2017, Loomstate product data, and other sources. Please contact Loomstate with any
            questions regarding methodology.
          </div>
          <div className="sm:columns-1">
            <img
              src="https://images.ctfassets.net/ctw2ty292274/6lMW69qGRMowjwOCAmngB9/a1c68debfe8797a921ab3a4f8e67f900/impacts-title.png"
              alt="Material Impacts 2021"
            />
          </div>
          <div></div>
          <div id="impacts-metrics" className={`sm:columns-1 md:columns-2 ${styles.impactsMetrics}`}>
            <div className={styles.impactCard}>
              <img
                className={styles.impactCardImg}
                src="https://images.ctfassets.net/ctw2ty292274/28IEQi1OIUyxayYOEyWKjZ/6fd29a63d9114c2d691d8029ff440ec3/impacts-water-saving.png"
                alt="Water Savings"
              />
            </div>
            <div className={styles.impactCard}>
              <img
                className={styles.impactCardImg}
                src="https://images.ctfassets.net/ctw2ty292274/1osINiJiXJpjFVrogNjzMx/16eca2ee7e13ac5de1e1a58e399e35a8/impacts-co2.png"
                alt="CO2"
              />
            </div>
            <div className={styles.impactCard}>
              <img
                className={styles.impactCardImg}
                src="https://images.ctfassets.net/ctw2ty292274/5wjcRA95uOaw7U1UtFBzqg/8b3f23853154e225d99efb4576843394/imapcts-rpet.png"
                alt="rPET"
              />
            </div>
            <div className={styles.impactCard}>
              <img
                className={styles.impactCardImg}
                src="https://images.ctfassets.net/ctw2ty292274/7czW9Xu5636DqG2pMLCOSL/28f362244f9ae21c04fab5f1d1a0a80f/impacts-pesticides.png"
                alt="Pesticides"
              />
            </div>
            <div className={styles.impactCard}>
              <img
                className={styles.impactCardImg}
                src="https://images.ctfassets.net/ctw2ty292274/4JVwC4nIsRd4BgfluSqBSa/d215bde343035ec5e7da4202aed94224/impacts-land.png"
                alt="Land Use"
              />
            </div>
            <div className={styles.impactCard}>
              <img
                className={styles.impactCardImg}
                src="https://images.ctfassets.net/ctw2ty292274/22n0Qxggemr1jipRmwNB6U/ca82949f7e93229c860dbf5181ceab98/impacts-cotton.png"
                alt="Cotton"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`sm:columns-1 ${styles.confection}`}>
        <img
          src="https://images.ctfassets.net/ctw2ty292274/5i0UD5okAJEJq7IMvQKbdI/274a2bef4000de64331836ca10d0f0aa/impacts-confection.png"
          alt=""
        />
        <div
          className={styles.introConfectionHolder}
          style={{ display: 'grid', gridTemplateColumns: 'repeat(12, minmax(0, 1fr))', columnGap: '20px' }}
        >
          <div style={{ gridColumn: '2 / span 10', position: 'relative' }} className="h-full">
            <div className={styles.introConfection}>
              Loomstate considers the full impact of materials and processes when we design a product - from the farm
              level to the finished garment. We report the annual environmental & social impacts for each of our private
              label clients based on their respective production volumes. What follows are some of our 2021 impacts
              across all clients.
            </div>
            <div className={styles.introConfectionTable}>
              <div className={styles.introConfectionTableYear}>2019</div>
              <h1 className={styles.introConfectionTableTitle}>
                <span className={styles.introConfectionTableTitleSpan}>MATERIAL</span> IMPACTS
              </h1>
              <div
                className={styles.introConfectionTableColumns}
                style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', columnGap: '20px' }}
              >
                <div className={styles.tableCol}>
                  <p>145 Million gallons of rain fed organic cotton agriculture water savings:</p>
                  <p>4.6 GAL A SECOND </p>
                  <p>Avoided 1.4 million lbs of synthetic pesticides: </p>
                  <p>2 Tons a day</p>
                </div>
                <div className={styles.tableCol}>
                  <p>235,344 kg of CO₂e Greenhouse gas Savings: (Compared to conventional) </p>
                  <p>33 KG AN HOUR </p>
                  <p>1,379 Organic acres supported:</p>
                  <p>2.15 sq miles</p>
                </div>
                <div className={styles.tableCol}>
                  <p>3.6 million plastic bottles recycled into polyester (rPET):</p>
                  <p>7 BOTTLES A MINUTE</p>
                  <p>Purchased 625,114 lbs of organic cotton:</p>
                  <p>6 tons a week</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default ImpactsTastic;
