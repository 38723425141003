import AccountButton from './components/account-button';
import CartButtonMenu from './components/cart-button-menu';

export interface Props {
  emptyCartTitle: string;
  emptyCartSubtitle: string;
  emptyCartImage: any;
  icon: any;
}

const UtilitySection: React.FC<Props> = ({ emptyCartTitle, emptyCartSubtitle, emptyCartImage, icon }) => {
  return (
    <div className="flex h-40 items-center">
      <div className="flex w-full justify-center">
        <AccountButton />
        <CartButtonMenu icon={icon} />
      </div>
    </div>
  );
};

export default UtilitySection;
