import React, { useCallback, useEffect, useState } from 'react';
import humanNumber from 'human-number';
import { Bar, BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Rectangle } from 'recharts';
import Overlay from 'components/commercetools-ui/atoms/overlay';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { useReports } from 'frontastic';

const SpentBarChart: React.FC = () => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { getOrderSummaryYear } = useReports();
  const [spentReportsData, setReportsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchReportsData = useCallback(async () => {
    setLoading(true);
    const reportsData = await getOrderSummaryYear();
    setLoading(false);
    setReportsData(reportsData);
  }, [getOrderSummaryYear]);

  const formatNumber = (number: number) => {
    return humanNumber(number, (n: number) => Number.parseFloat(n?.toString()).toFixed(2));
  };
  const renderTooltipContent = (content: any) => {
    const { payload, label } = content;

    return (
      <div className="customized-tooltip-content rounded-md border-slate-100 bg-slate-50 p-8">
        <p className="year">{`Year: ${label}`}</p>
        <p className="amount">{`Amount: $${formatNumber(payload[0]?.value)}`}</p>
      </div>
    );
  };

  useEffect(() => {
    fetchReportsData();
  }, [fetchReportsData]);

  return (
    <>
      {loading && <Overlay />}
      <div className="relative mt-20 h-fit rounded-lg border border-gray-300 p-8 shadow-md">
        <Typography fontFamily="BarlowSemiCondensedRegular" className="my-15 text-left text-28 font-bold md:text-36">
          {formatAccountMessage({
            id: 'spent.years',
            defaultMessage: 'Spent through years',
          })}
        </Typography>
        <ResponsiveContainer width="100%" height={450}>
          <BarChart
            width={300}
            height={300}
            data={spentReportsData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis tickFormatter={(number) => humanNumber(number)} />
            <Tooltip content={renderTooltipContent} />
            <Legend />
            <Bar name={'Amount spent'} dataKey="value" fill="#85BB65" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default SpentBarChart;
