import React from 'react';
import humanNumber from 'human-number';
import moment from 'moment';
import { XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, AreaChart, Area } from 'recharts';
import Typography from 'components/commercetools-ui/atoms/typography';
import DatePicker from './date-picker';
interface Props {
  onDatesSelected: any;
  reportsData: any;
  startDate: any;
  endDate: any;
}

const Chart: React.FC<Props> = ({ reportsData, onDatesSelected, startDate, endDate }) => {
  const formatNumber = (number: number) => {
    return humanNumber(number, (n: number) => Number.parseFloat(n.toString()).toFixed(2));
  };

  reportsData.forEach((entry: { date: string | number | Date }) => {
    entry.date = new Date(entry.date);
  });

  const handleDatesSelected = (startDate: any, endDate: any) => {
    onDatesSelected(startDate, endDate);
  };

  const formatValue = (decimal: number) => {
    return humanNumber(decimal);
  };

  const renderTooltipContent = (content: any) => {
    const { payload, label } = content;
    const date = moment(label);
    const formattedDate = date.format('YYYY-MM-DD');
    const total = payload.reduce((result: any, entry: { value: any }) => result + entry.value, 0);

    return (
      <div className="customized-tooltip-content rounded-md border-slate-100 bg-slate-50 p-8">
        <p className="total">{`Date: ${formattedDate}`}</p>
        <p className="total">{`Amount: $${formatNumber(total)}`}</p>
      </div>
    );
  };

  return (
    <div className="total-spent mt-20 flex flex-col rounded-md border-slate-100 md:flex-row">
      <div className="chart relative grow rounded-lg border border-gray-300 p-8 shadow-md md:w-1/2">
        <Typography
          fontFamily="BarlowSemiCondensedRegular"
          className="mt-52 text-left text-28 font-bold sm:mt-0 md:text-36"
        >
          Spent over time
        </Typography>
        <div className="absolute right-0 top-0 hidden 2xl:block">
          <DatePicker
            onDatesSelected={handleDatesSelected}
            startDate={startDate}
            endDate={endDate}
            orientation="horizontal"
          />
        </div>
        <div className="absolute right-0 top-0 2xl:hidden">
          <DatePicker
            onDatesSelected={handleDatesSelected}
            startDate={startDate}
            endDate={endDate}
            orientation="vertical"
            numberOfMonths={1}
          />
        </div>
        <div className="mt-20">
          <ResponsiveContainer width="100%" height={450}>
            <AreaChart width={730} height={350} data={reportsData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorTotal_invoiced" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#85BB65" stopOpacity={1} />
                  <stop offset="95%" stopColor="#85BB65" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                type="category"
                tickFormatter={(date) => `${date.toLocaleString('default', { month: 'short' })}-${date.getFullYear()}`}
                style={{
                  fontSize: '1rem',
                  fontFamily: 'BarlowSemiCondensedRegular',
                }}
              />
              <YAxis
                style={{
                  fontSize: '1rem',
                  fontFamily: 'BarlowSemiCondensedRegular',
                }}
                tickFormatter={formatValue}
              />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip content={renderTooltipContent} />
              <Area
                type="monotone"
                dataKey="invoice_accumulated"
                stroke="#85BB65"
                fillOpacity={1}
                fill="url(#colorTotal_invoiced)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Chart;
