import React from 'react';
import EcoSection from 'components/commercetools-ui/organisms/eco-section';
import { formatCustomerGroup } from 'helpers/formatter';

interface Props {
  data: any;
  customerGroup: any;
}

const EcoSectionTastic: React.FC<Props> = ({ data, customerGroup }) => {
  if (!data) {
    return <div>Loading...</div>;
  }

  const formattedCustomerGroup = formatCustomerGroup(customerGroup?.obj?.key!);

  const getTagId = (tag: any) => tag.sys.id;
  const isMatchingTag = (tagId: string) => tagId === formattedCustomerGroup || tagId === 'allUsers';

  const filterByTags = (items: any[]) =>
    items?.filter((item) => item.metadata?.tags?.some((tag: any) => isMatchingTag(getTagId(tag))));

  const containsMatchingTag = (dataItem: any) =>
    dataItem?.metadata?.tags?.some((tag: any) => isMatchingTag(getTagId(tag)));

  const getFields = (dataItem: any) => dataItem?.fields;

  const dataSourceItems = data?.data?.dataSource?.items[0]?.fields;
  const miniCards = filterByTags(dataSourceItems?.miniCards).map(getFields);

  const titleInfo = containsMatchingTag(dataSourceItems?.title) ? getFields(dataSourceItems?.title) : {};
  const tileInfo = containsMatchingTag(dataSourceItems?.tile) ? getFields(dataSourceItems?.tile) : {};

  const textTileFields = getFields(dataSourceItems?.textTile);
  const textTileInfo = {
    icon: textTileFields?.mainIcon?.fields?.file?.url,
    mainText: textTileFields?.mainText,
    ctaText: textTileFields?.ctaLabel,
    ctaReference: textTileFields?.ctaReference,
    resultsEco: filterByTags(textTileFields?.resultsEco)?.map((res: any) => ({
      icon: res?.fields?.icon?.fields?.file?.url,
      leftText: res?.fields?.leftText,
      rightText: res?.fields?.rightText,
    })),
    tileContent: containsMatchingTag(textTileFields?.tile) ? getFields(textTileFields?.tile) : {},
  };

  const ecoData = {
    titleInfo,
    miniCards,
    tileInfo,
    textTileInfo,
  };

  return <EcoSection {...ecoData} />;
};

export default EcoSectionTastic;
