import React from 'react';
import NextLink from 'next/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { renderIcon } from './renderIcon';

export interface MiniCard {
  text: string;
  icon: string;
  href: string;
}

export interface Props {
  miniCard: MiniCard;
}

const MiniCard: React.FC<Props> = ({ miniCard }) => {
  const containerStyle = ` min-h-[100px] rounded-lg flex items-center justify-between px-20 py-20 bg-100 hover:shadow-lg transition-shadow space-x-4 w-full`;

  return (
    <div className="ml-10 w-full rounded-md bg-gray-100">
      <NextLink href={miniCard.href}>
        <a>
          <div className={containerStyle}>
            <div className="flex grow items-center space-x-2">
              <Typography fontFamily="NunitoSansBold" fontSize={16}>
                {miniCard.text}
              </Typography>
            </div>
            <div className="flex h-full w-1/2 items-center justify-end">{renderIcon(miniCard.icon)}</div>
          </div>
        </a>
      </NextLink>
    </div>
  );
};

export default MiniCard;
