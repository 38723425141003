import { InboxIcon, QuestionMarkCircleIcon, ArrowRightCircleIcon } from '@heroicons/react/24/outline';

export const renderIcon = (name: string) => {
  switch (name) {
    case 'question':
      return <QuestionMarkCircleIcon className="h-20 w-20 text-black" aria-hidden="true" />;
    case 'inbox':
      return <InboxIcon className="h-20 w-20 text-black" aria-hidden="true" />;
    case 'rightArrow':
      return <ArrowRightCircleIcon className="h-20 w-20 text-black" aria-hidden="true" />;
  }
};
