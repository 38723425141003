import React, { useCallback, useEffect, useState } from 'react';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Overlay from 'components/commercetools-ui/atoms/overlay';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { useReports } from 'frontastic';
import DatePicker from './date-picker';

interface Props {
  startDate: any;
  endDate: any;
}

const BarChart: React.FC<Props> = ({ startDate, endDate }) => {
  const { getOrdersRegion } = useReports();
  const [ordersRegionData, setOrdersRegionData] = useState([]);
  const [minDateSelected, setMinDateSelected] = useState(new Date(new Date().getFullYear(), 0, 1));
  const [maxDateSelected, setMaxDateSelected] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const formatDate = (date: any) => {
    const isoString = date?.toISOString();
    return isoString?.substring(0, 10);
  };

  const fetchReportsData = useCallback(
    async (minDate, maxDate) => {
      setLoading(true);
      const reportsData = await getOrdersRegion(minDate, maxDate);
      setLoading(false);
      setOrdersRegionData(reportsData);
    },
    [getOrdersRegion, minDateSelected, maxDateSelected],
  );

  useEffect(() => {
    fetchReportsData(minDateSelected, maxDateSelected);
  }, [fetchReportsData]);

  const handleDatesSelected = async (startDate: string | Date, endDate: string | Date) => {
    const minDate = formatDate(startDate);
    const maxDate = formatDate(endDate);

    if (minDate !== undefined && maxDate !== undefined) {
      setMinDateSelected(minDate);
      setMaxDateSelected(maxDate);
    }
  };

  return (
    <>
      {loading && <Overlay />}
      <div className="relative mt-20 h-fit rounded-lg border border-gray-300 p-8 shadow-md">
        <Typography
          fontFamily="BarlowSemiCondensedRegular"
          className="mt-52 text-left text-28 font-bold sm:mt-0 md:text-36"
        >
          Orders by region
        </Typography>
        <div className="absolute right-0 top-0 hidden 2xl:block">
          <DatePicker
            onDatesSelected={handleDatesSelected}
            startDate={startDate}
            endDate={endDate}
            orientation="horizontal"
          />
        </div>
        <div className="absolute right-0 top-0 2xl:hidden">
          <DatePicker
            onDatesSelected={handleDatesSelected}
            startDate={startDate}
            endDate={endDate}
            orientation="vertical"
            numberOfMonths={1}
          />
        </div>
        {ordersRegionData && (
          <ResponsiveContainer width="100%" height={1200}>
            <ComposedChart
              layout="vertical"
              width={500}
              height={400}
              data={ordersRegionData}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis type="number" />
              <YAxis dataKey="invoice_region" type="category" scale="band" />
              <Tooltip />
              <Legend />
              <Bar name={'Orders quantity'} dataKey="order_count" barSize={20} fill="#413ea0" />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
    </>
  );
};

export default BarChart;
