import React from 'react';

export interface TickerProps {
  firstText: string;
  secondText: string;
  repetitionFactor: number;
}

const Ticker: React.FC<TickerProps> = ({ firstText, secondText, repetitionFactor }) => {
  const texts = Array(repetitionFactor).fill({ firstText, secondText });

  return (
    <div className="tcontainer w-full overflow-hidden">
      <div className="ticker-wrap h-48 w-full bg-[#A68A6D] pt-14">
        <div className="ticker-move animate-ticker inline-block whitespace-nowrap">
          <div className="flex justify-between">
            {!!repetitionFactor &&
              texts.map((text, index) => (
                <div key={index} className="ticker-item">
                  <span className="mr-5 text-white"> - {text.firstText} </span>
                  <span className="mr-5 text-black"> {text.secondText} </span>
                </div>
              ))}
          </div>
        </div>
        {!repetitionFactor && (
          <div className="ticker-item px-16 md:px-24 xl:px-48">
            <span className="mr-5 text-white"> {firstText} </span>
            <span className="mr-5 text-black"> {secondText} </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Ticker;
