import React from 'react';
import NextLink from 'next/link';

interface Image {
  fields: {
    file: {
      url: string;
    };
  };
}
export interface MiniCard {
  image?: Image;
  url: string;
}

export interface Props {
  miniCard: MiniCard;
}

const MiniCard: React.FC<Props> = ({ miniCard }) => {
  return (
    <div>
      <NextLink href={miniCard.url}>
        <a>
          <img
            className="bg-100 flex h-[160px] w-[160px] items-center space-x-4 bg-cover p-20 transition-shadow"
            src={miniCard.image?.fields?.file?.url}
          />
        </a>
      </NextLink>
    </div>
  );
};

export default MiniCard;
