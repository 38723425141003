import React from 'react';
import Ticker from 'components/commercetools-ui/organisms/ticker';

interface Props {
  data: any;
}

const TickerTastic: React.FC<Props> = ({ data }) => {
  const firstText = data?.data?.dataSource?.fields?.firstText; // Added ? here to make sure we don't run into undefined issues on build time
  const secondText = data?.data?.dataSource?.fields?.secondText;
  const repetitionFactor = data?.data?.dataSource?.fields?.repetitionFactor;

  if (!firstText) return <></>; // Don't render the component if there's no data to show

  return <Ticker firstText={firstText} secondText={secondText} repetitionFactor={repetitionFactor} />;
};

export default TickerTastic;
