import React from 'react';
import { useRouter } from 'next/router';
import { Money } from '@commercetools/frontend-domain-types/product/Money';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import Typography from '../../../atoms/typography';

interface Props {
  discountedPrice?: Money;
  price?: Money;
}

const Prices: React.FC<Props> = ({ price, discountedPrice }) => {
  const { locale } = useRouter();

  return (
    <>
      {discountedPrice ? (
        <div className="flex items-center gap-8">
          <Typography
            as="h4"
            medium
            lineHeight="loose"
            fontFamily="BarlowSemiCondensedThin"
            fontSize={14}
            className="text-accent-red md:text-14"
          >
            {CurrencyHelpers.formatForCurrency(discountedPrice, locale)}
          </Typography>
          <Typography
            as="h5"
            medium
            lineHeight="loose"
            fontFamily="BarlowSemiCondensedThin"
            fontSize={14}
            className="text-gray-500 line-through md:text-12"
          >
            {CurrencyHelpers.formatForCurrency(price ?? '', locale)}
          </Typography>
        </div>
      ) : (
        <Typography
          as="h4"
          medium
          fontSize={14}
          fontFamily="BarlowSemiCondensedThin"
          lineHeight="loose"
          className="text-sm leading-3 text-black"
        >
          {CurrencyHelpers.formatForCurrency(price ?? 0, locale)}
        </Typography>
      )}
    </>
  );
};

export default Prices;
