import React from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';

const About: React.FC = () => {
  return (
    <div className="my-32 grid grid-cols-1 gap-52 lg:grid-cols-8">
      <div id="about-menu" className="col-span-8 lg:col-span-2">
        <ul id="about-menu-list" className="sticky top-[150px] font-NunitoSansSemiBold text-16">
          <li className="active border-b py-10">
            <a href="#philosophy">Our Philosophy</a>
          </li>
          <li className="border-b py-10">
            <a href="#employee">Employee Perks</a>
          </li>
          <li className="border-b py-10">
            <a href="#sustainability">Sustainability Vision</a>
          </li>
          <li className="border-b py-10">
            <a href="#partner">Featured Partner</a>
          </li>
        </ul>
      </div>
      <div className="col-span-8 lg:col-span-6">
        <div className="flex flex-wrap" id="philosophy">
          <div>
            <img
              className="mx-auto"
              src="/images/about-page_intro.jpg"
              title="About page_intro.jpg"
              alt="About page_intro.jpg"
            />
          </div>
          <div className="my-40 w-full">
            <div className="flex flex-col gap-32 lg:flex-row">
              <div className="w-full lg:w-3/4">
                <article>
                  <Typography className="mb-24" fontSize={36}>
                    Our Goods Philosophy
                  </Typography>
                  <p className="mb-24 text-left font-semibold xl:text-justify">
                    At Chipotle, we approach food differently. We’ve never been about quick bucks, fast-follows, or
                    mindless brand and product evolution. From the very beginning, we imagined a better world, and chose
                    to make it real.
                  </p>
                  <p className="mb-24 text-left font-semibold xl:text-justify">
                    Our approach to “merch” is different too. We don’t crank out brand swag. We design apparel. Because
                    we’re as mindful of waste in fashion as we are of waste in food. Our mission is bigger than selling
                    people disposable billboards or lawn-mowing uniforms. We take the same care, respect, and
                    big-picture thinking we apply in the kitchen to everything else we make, too. Which means, it’s not
                    only time to move on from “fast food”, but “fast fashion” as well.
                  </p>
                  <p className="text-left xl:text-justify">
                    Let’s make food worth sharing and clothes worth wearing because better burritos and better apparel
                    can make the world a better place.
                  </p>
                </article>
              </div>
              <div className="w-full self-center lg:w-1/4">
                <img
                  className="mx-auto"
                  src="/images/philosophy-badge.png"
                  title="Philosophy badge.png"
                  alt="Philosophy badge.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="my-40 flex flex-wrap gap-32" id="employee">
          <div className="flex flex-col items-center gap-32 lg:flex-row">
            <div className="w-full">
              <article>
                <div className="mb-24">
                  <p>EMPLOYEES ONLY</p>
                </div>
                <Typography className="mb-24" fontSize={36}>
                  Exclusive Partnerships
                </Typography>
                <p className="mb-24 text-left xl:text-justify">
                  See what’s new from our one-of-a-kind collections, thoughtfully designed with hand-selected partners,
                  exclusively for employees like you.
                </p>
                <p>
                  <strong>CHIPOTLE X PATAGONIA</strong>
                </p>
                <p>
                  <strong>CHIPOTLE X TRAVIS MATHEW</strong>
                </p>
              </article>
            </div>
            <div className="w-full">
              <img
                className="img-responsive w-full"
                src="/images/about-page_feature_patagonia.jpg"
                title="About page_feature_Patagonia.jpg"
                alt="About page_feature_Patagonia.jpg"
              />
            </div>
          </div>

          <div className="flex flex-col-reverse items-center gap-32 lg:flex-row">
            <div className="w-full">
              <img
                className="img-responsive w-full"
                src="/images/about-page_feature_goods.jpg"
                title="About page_feature_Goods.jpg"
                alt="About page_feature_Goods.jpg"
              />
            </div>
            <div className="w-full">
              <article>
                <Typography className="mb-24" fontSize={36}>
                  Discounts &amp; Early Access
                </Typography>

                <p className="text-left xl:text-justify">
                  Employees get Chipotle Goods for less. We’ll also let you cut in line from time to time, with early
                  access to fresh designs.
                </p>
              </article>
            </div>
          </div>
          <div className="flex flex-col items-center gap-32 lg:flex-row">
            <div className="w-full">
              <article className="w-full">
                <Typography className="mb-24" fontSize={36}>
                  Uniforms for the Greater Good
                </Typography>
                <p className="mb-24 text-left xl:text-justify">
                  Every graphic tee, polo, henley, woven shirt and hat worn by our employees is made from organic and
                  sustainably-sourced materials.
                </p>
                <p>
                  <Link className="text-[#A81612]" href="/impacts">
                    <strong>LEARN MORE</strong>
                  </Link>
                </p>
              </article>
            </div>
            <div className="w-full">
              <img
                className="img-responsive w-full"
                src="/images/uniforms-about.png"
                title="uniforms about.png"
                alt="uniforms about.png"
              />
            </div>
          </div>
        </div>
        <div className="my-40" id="sustainability">
          <img
            className="img-responsive w-full"
            src="/images/sustainability.png"
            title="sustainability.png"
            alt="sustainability.png"
          />
          <div className="my-40 w-full gap-32">
            <article>
              <Typography fontSize={36}>Sustainable Practice Makes Perfect</Typography>
              <p className="mt-24">
                <strong>We’re taking steps toward a climate positive future</strong>
              </p>
              <p className="mb-24 text-left xl:text-justify">
                Our aim is to not only be less wasteful, but climate <em>positive</em> as we close the loop on food and
                fashion ― which means, we do more good than harm when it comes to our environmental and social impact
                around the world. But real change takes time. And while we’ve come a long way, we know we can do better.
                That’s why we work with like-minded partners to find creative solutions and sustainable systems where
                everybody wins.{' '}
              </p>
              <div className="grid grid-cols-2 justify-items-center gap-24 lg:grid-cols-3 lg:gap-32">
                <div className="self-center">
                  <img src="/images/flowstate_icon-3.png" />
                </div>
                <div className="self-center">
                  <img src="/images/loomstate-2.png" />
                </div>
                <div className="self-center">
                  <img src="/images/anthem-icon.png" />
                </div>
                <div className="self-center">
                  {' '}
                  <img src="/images/travis_matthews_logo.png" />
                </div>
                <div className="self-center">
                  {' '}
                  <img src="/images/patagonia-inc-logo-vector.png" />
                </div>
              </div>
            </article>
          </div>
        </div>

        <div className="my-40 flex flex-wrap" id="partner">
          <div className="mb-24 flex w-full flex-col items-center gap-32 lg:flex-row">
            <div className="w-full">
              <article>
                <div className="mb-24">
                  <p>FEATURED PARTNER</p>
                </div>
                <Typography className="mb-24" fontSize={36}>
                  Loomstate
                </Typography>
                <p className="mb-24 text-left xl:text-justify">
                  As a pioneer in sustainable fashion, Loomstate was a natural fit to become our exclusive restaurant
                  uniform partner. As we launch Chipotle Goods, they play an integral role in our shared vision of
                  thoughtfully-designed apparel made from 100% certified organic materials and socially-responsible
                  methods of production.
                </p>
                <p>
                  <Link className="text-[#A81612]" href="/impacts">
                    <strong>Read their Impact Review</strong>
                  </Link>
                </p>
              </article>
            </div>
            <div className="w-full">
              <img
                className="img-responsive w-full"
                src="/images/partner-top.png"
                title="partner-top.png"
                alt="partner-top.png"
              />
            </div>
          </div>
          <div className="w-full">
            <img
              className="img-responsive w-full"
              src="/images/partner-center.png"
              title="partner-center.png"
              alt="partner-center.png"
            />
          </div>
          <div className="my-40">
            <div className="flex flex-col lg:flex-row lg:gap-16">
              <div className="mb-24">
                <Typography fontSize={18}>Organic Cotton Looks Better on Everyone</Typography>
              </div>
              <article className="w-full">
                <p className="text-left xl:text-justify">
                  Organic farmers avoid the use of petroleum-based chemical pesticides and fertilizers, instead relying
                  on natural methods and a deep connection to their land to yield a healthy crop. This allows water to
                  stay clean and free of chemicals, allows soil to naturally retain necessary nutrients and moisture,
                  and provides a healthy working environment for growers and their families.
                </p>
              </article>
            </div>
          </div>

          <div className="partner-carousel hidden w-full">
            <div>
              <div>
                <div
                  id="r9_r1_r13_r1"
                  className="carousel slide"
                  data-ride="carousel"
                  data-interval="3000"
                  data-pause="hover"
                  data-wrap="true"
                >
                  <div className="carousel-inner">
                    <div className="item">
                      <div className="w-1/3" data-slide="prev" data-target="#r9_r1_r13_r1">
                        <img src="/images/carousel-1.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                      <div className="w-1/3">
                        <img src="/images/carousel-2.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                      <div className="w-1/3" data-slide="next" data-target="#r9_r1_r13_r1">
                        <img src="/images/carousel-3.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                    </div>
                    <div className="item active">
                      <div className="w-1/3" data-slide="prev" data-target="#r9_r1_r13_r1">
                        <img src="/images/carousel-2.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                      <div className="w-1/3">
                        <img src="/images/carousel-3.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                      <div className="w-1/3" data-slide="next" data-target="#r9_r1_r13_r1">
                        <img src="/images/carousel-1.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="w-1/3" data-slide="prev" data-target="#r9_r1_r13_r1">
                        <img src="/images/carousel-3.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                      <div className="w-1/3" data-target="#r9_r1_r13_r1">
                        <img src="/images/carousel-1.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                      <div className="w-1/3" data-slide="next" data-target="#r9_r1_r13_r1">
                        <img src="/images/carousel-2.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                    </div>
                  </div>
                  <ol className="carousel-indicators">
                    <li data-target="#r9_r1_r13_r1" data-slide-to="0" className=""></li>
                    <li data-target="#r9_r1_r13_r1" data-slide-to="1" className="active"></li>
                    <li data-target="#r9_r1_r13_r1" data-slide-to="2" className=""></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-24 flex w-full flex-col items-center gap-32 lg:flex-row">
            <div className="w-full">
              <img
                className="img-responsive w-full"
                src="/images/partner-center-2.png"
                title="partner-center-2.png"
                alt="partner-center-2.png"
              />
            </div>

            <div className="w-full">
              <article>
                <Typography className="mb-24" fontSize={18}>
                  Committed to Communities
                </Typography>
                <p className="text-left xl:text-justify">
                  Loomstate believes our farmers, thread makers, fabric knitters, printers, cutters &amp; sewers are our
                  community. They’re helping to lead and organize groundbreaking collaborative supply chain
                  partnerships, like organizing small and marginal land holders into cooperatives to ensure the best
                  market access and prices through the Chetna Coalition, for truly sustainable clothing and textile
                  production from farm to shelf.
                </p>
              </article>
            </div>
          </div>
          <div className="w-full">
            <img
              className="img-responsive w-full"
              src="/images/partner-bottom.png"
              title="partner-bottom.png"
              alt="partner-bottom.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
