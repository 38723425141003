import React from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';

export interface Title {
  mcTitle: string;
  rightText: string;
  linkText: string;
  linkUrl: string;
}

export interface Props {
  title: Title;
}

const Title: React.FC<Props> = ({ title }) => {
  return (
    <div className="my-20 flex flex-col justify-between gap-16 md:flex-row">
      <div className="text-left uppercase">
        <Typography fontFamily="NunitoSansSemiBold" fontSize={14}>
          {title.mcTitle}
        </Typography>
      </div>
      <div className="flex items-end space-x-10">
        <Typography fontFamily="NunitoSansBold" fontSize={12} className="pr-5">
          {title.rightText}
        </Typography>
        <Link>
          <a href={title.linkUrl}>
            <Typography fontFamily="NunitoSansBold" fontSize={12} underline>
              {title.linkText}
            </Typography>
          </a>
        </Link>
      </div>
    </div>
  );
};

export default Title;
