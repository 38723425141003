import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({}: Props) => (
  <svg
    className="h-full"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
  >
    <g id="icon_emission" opacity="0.6">
      <path
        id="Vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.84298 2.27418C3.68067 2.27418 2.72727 3.29744 2.72727 4.57449C2.72727 5.32967 3.06141 5.99794 3.57629 6.41713C3.73581 6.54701 3.76622 6.78995 3.64421 6.95976C3.52221 7.12957 3.29398 7.16195 3.13446 7.03207C2.44492 6.47068 2 5.57774 2 4.57449C2 2.88313 3.26667 1.5 4.84298 1.5C6.02007 1.5 7.02489 2.27201 7.45793 3.36642C7.62563 3.31762 7.80187 3.29159 7.98347 3.29159C9.10335 3.29159 10 4.27366 10 5.47029C10 6.36032 9.50537 7.12886 8.79245 7.46648C8.60895 7.55338 8.39403 7.46548 8.3124 7.27016C8.23076 7.07483 8.31334 6.84604 8.49683 6.75914C8.95143 6.54385 9.27273 6.04962 9.27273 5.47029C9.27273 4.68796 8.68934 4.06577 7.98347 4.06577C7.77435 4.06577 7.57766 4.11972 7.40354 4.21548C7.30562 4.26933 7.19004 4.273 7.08927 4.22545C6.98851 4.1779 6.91345 4.08427 6.88517 3.97087C6.64029 2.98878 5.8149 2.27418 4.84298 2.27418ZM3.45455 4.59672C3.45455 3.74159 4.10577 3.04836 4.90909 3.04836C5.31066 3.04836 5.6748 3.2221 5.93761 3.50189C6.07962 3.65306 6.07961 3.89815 5.93759 4.04932C5.79558 4.20048 5.56533 4.20047 5.42333 4.04929C5.29134 3.90878 5.11003 3.82254 4.90909 3.82254C4.50743 3.82254 4.18182 4.16916 4.18182 4.59672C4.18182 4.81062 4.26283 5.00363 4.39483 5.14413C4.53684 5.29529 4.53685 5.54039 4.39485 5.69156C4.25285 5.84274 4.02261 5.84275 3.88059 5.69159C3.61775 5.41182 3.45455 5.02419 3.45455 4.59672Z"
        fill="#0D9D00"
      />
      <path
        id="Ellipse 7 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 9.75C6.41421 9.75 6.75 9.41421 6.75 9C6.75 8.58579 6.41421 8.25 6 8.25C5.58579 8.25 5.25 8.58579 5.25 9C5.25 9.41421 5.58579 9.75 6 9.75ZM6 10.5C6.82843 10.5 7.5 9.82843 7.5 9C7.5 8.17157 6.82843 7.5 6 7.5C5.17157 7.5 4.5 8.17157 4.5 9C4.5 9.82843 5.17157 10.5 6 10.5Z"
        fill="#0D9D00"
      />
    </g>
  </svg>
);

export default Icon;
