import React from 'react';

type Props = {
  className?: string;
  size?: number;
};

const Icon: React.FC<Props> = ({}: Props) => (
  <svg
    className="h-full"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
  >
    <g id="icon_water" opacity="0.6">
      <path
        id="Vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.20872 1.5792L6 1.90909L5.79128 1.5792C5.91552 1.4736 6.08448 1.4736 6.20872 1.5792ZM6 2.43206C5.94787 2.48256 5.88757 2.54244 5.82088 2.61116C5.58237 2.8569 5.26459 3.21305 4.94761 3.65394C4.3964 4.42062 3.8759 5.40406 3.74009 6.48385C3.88368 6.44848 4.052 6.41329 4.23563 6.38668C4.78601 6.30694 5.53354 6.29502 6.15784 6.65683C6.59236 6.90865 7.16836 6.92058 7.67681 6.84691C7.91694 6.81212 8.12874 6.76027 8.28295 6.71612C8.19813 5.54532 7.64288 4.47526 7.05239 3.65394C6.73541 3.21305 6.41763 2.8569 6.17912 2.61116C6.11243 2.54244 6.05213 2.48256 6 2.43206ZM8.24639 7.56492C8.1058 7.59916 7.94227 7.633 7.76437 7.65877C7.21399 7.73851 6.46646 7.75044 5.84216 7.38863C5.40764 7.1368 4.83164 7.12488 4.32319 7.19855C4.08503 7.23305 3.87474 7.28433 3.72086 7.32825C3.85159 8.65293 4.82213 9.68182 6 9.68182C7.10672 9.68182 8.03039 8.77349 8.24639 7.56492ZM5.79128 1.5792C5.79139 1.57911 5.79128 1.5792 6 1.90909C6.20872 1.5792 6.20861 1.57911 6.20872 1.5792L6.20918 1.57959L6.20988 1.58019L6.212 1.582L6.21907 1.58811C6.22501 1.59326 6.2334 1.6006 6.24408 1.61005C6.26542 1.62896 6.2959 1.65638 6.33422 1.69192C6.41083 1.76295 6.51895 1.86659 6.64808 1.99964C6.90589 2.26526 7.24988 2.65059 7.59467 3.13015C8.27652 4.07855 9 5.44914 9 7.02273C9 8.94318 7.65686 10.5 6 10.5C4.34314 10.5 3 8.94318 3 7.02273C3 5.44914 3.72348 4.07855 4.40533 3.13015C4.75012 2.65059 5.09411 2.26526 5.35192 1.99964C5.48105 1.86659 5.58917 1.76295 5.66578 1.69192C5.7041 1.65638 5.73458 1.62896 5.75592 1.61005C5.7666 1.6006 5.77499 1.59326 5.78093 1.58811L5.788 1.582L5.79012 1.58019L5.79082 1.57959L5.79128 1.5792Z"
        fill="#0D9D00"
      />
    </g>
  </svg>
);

export default Icon;
