import React from 'react';
import MiniCardContainer, { MiniCardContainerProps } from 'components/commercetools-ui/organisms/mini-card';
import { formatCustomerGroup } from 'helpers/formatter';

interface Props {
  data: any;
  customerGroup: any;
}

const MiniCardTastic: React.FC<Props> = ({ data, customerGroup }) => {
  if (!data) {
    return <div>Loading...</div>;
  }
  const fields = data?.data?.dataSource?.items[0]?.fields; // TODO: Check items array
  const categoryCards = data?.data?.dataSource?.items[0]?.fields?.categoryCards;

  const titleInfo = {
    mcTitle: fields?.categoryTitle,
    rightText: fields?.rightText,
    linkText: fields?.linkText,
    linkUrl: fields?.linkUrl,
  };

  const formattedCustomerGroup = formatCustomerGroup(customerGroup?.obj?.key!);

  const filteredData = categoryCards.filter((item: { metadata: { tags: any[] } }) =>
    item.metadata.tags.some((tag) => tag.sys.id === formattedCustomerGroup || tag.sys.id === 'allUsers'),
  );

  const miniCards = filteredData.map((obj: any) => ({
    text: obj?.fields?.categoryTitle,
    href: obj?.fields?.categoryLink,
    icon: obj?.fields?.categoryIcon,
  }));

  const cardsData = {
    titleInfo,
    miniCards,
  };

  return <MiniCardContainer {...cardsData} />;
};

export default MiniCardTastic;
