import { useEffect, useState } from 'react';
import { Popover } from '@headlessui/react';
import Link from 'components/commercetools-ui/atoms/link';
import CartIcon from 'components/icons/cart';
import { useFormat } from 'helpers/hooks/useFormat';
import useViewportWidth from 'helpers/hooks/useViewportWidth';
import { useCart } from 'frontastic';

export interface Props {
  icon?: any;
}

const CartButtonMenu: React.FC<Props> = ({ icon }) => {
  const { totalItems: totalCartItems } = useCart();
  const { viewportWidth } = useViewportWidth();
  const { formatMessage: formatCartMessage } = useFormat({ name: 'Cart' });
  const [showPopover, setShowPopover] = useState(viewportWidth >= 1024);

  useEffect(() => {
    setShowPopover(viewportWidth >= 1024);
  }, [viewportWidth]);

  return (
    <div className="ml-10 mt-4 h-40 w-40">
      <div
        title={formatCartMessage({ id: 'myCart', defaultMessage: 'My Cart' })}
        className="h-fit cursor-pointer border-secondary-regular hover:border-b-2"
      >
        <Link
          link={'/cart'}
          title={formatCartMessage({ id: 'myCart', defaultMessage: 'My Cart' })}
          className="lg:hidden"
        >
          <CartIcon
            className="w-50 text-secondary-regular lg:hidden"
            totalCartItems={totalCartItems}
            counterClassName="-translate-y-1/4"
            icon={icon}
          />
        </Link>
        {showPopover && (
          <Popover as="div" className="relative h-fit lg:flex">
            {({ close }) => (
              <>
                <Popover.Button>
                  {' '}
                  <CartIcon
                    className="hidden w-50 text-secondary-regular lg:flex"
                    totalCartItems={totalCartItems}
                    counterClassName="-translate-y-1/4"
                    icon={icon}
                  />
                </Popover.Button>

                <Popover.Overlay className="fixed inset-0 z-[310] bg-secondary-regular opacity-30" />
                <Popover.Panel className="absolute top-[60px] z-[310] animate-[appearDropdown_0.15s_ease-in-out] rounded-md bg-white shadow-400 lg:left-[-62px] xl:left-[-28px]">
                  <div className="absolute -top-20 z-10 w-31 -translate-x-1/2 overflow-hidden lg:left-[85px] xl:left-1/2">
                    <div className="h-21 w-21 origin-bottom-left rotate-45 bg-white" />
                  </div>
                  <div className="flex w-[100px] flex-col rounded-lg bg-white p-14 font-NunitoSansRegular text-16">
                    <Link
                      link="/cart"
                      className="my-12 text-14 hover:underline hover:underline-offset-2"
                      title="My Cart"
                      onClick={() => close()}
                    >
                      My Cart
                    </Link>
                    <Link
                      link="/checkout"
                      className="my-12 text-14 hover:underline hover:underline-offset-2"
                      title="Checkout "
                      onClick={() => close()}
                    >
                      Checkout
                    </Link>
                  </div>
                </Popover.Panel>
              </>
            )}
          </Popover>
        )}
      </div>
    </div>
  );
};

export default CartButtonMenu;
