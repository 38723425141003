import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import useClassNames from 'helpers/hooks/useClassNames';
import { Reference } from 'types/reference';
import Image, { NextFrontasticImage } from 'frontastic/lib/image';

interface Image extends NextFrontasticImage {
  fields?: {
    file: {
      url: string;
    };
  };
}
export interface TileProps {
  image?: Image;
  title: string;
  subtitle: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  roundedBorders?: boolean;
  className?: string;
  alignment?: string;
}
interface ContentAlignment {
  middle?: string;
  left?: string;
  right?: string;
}

const Tile: React.FC<TileProps> = ({
  image,
  title,
  subtitle,
  ctaLabel,
  ctaReference,
  className = '',
  alignment = '',
}) => {
  const contentAlignment = {
    middle: 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-4 py-2 rounded-lg',
    left: 'absolute top-1/2 flex max-w-md -translate-y-1/2 flex-col pl-40 md:left-10 md:max-w-xl md:text-left',
    right: 'absolute top-1/2 right-0 transform -translate-y-1/2 px-4 py-2',
  };

  const contentAlignmentClass = alignment
    ? contentAlignment[alignment as keyof ContentAlignment]
    : contentAlignment['middle'];

  if (!image) {
    return null;
  }

  return (
    <div className={`relative w-full ${className}`}>
      {image ? (
        <div
          className="relative h-[296px] rounded-md brightness-[.80] md:h-[532px] lg:h-[668px]"
          style={{ backgroundImage: `url(${image?.fields?.file?.url})`, backgroundSize: 'cover' }}
        ></div>
      ) : null}
      <div className={contentAlignmentClass}>
        <Typography className={`text-[#FFFFFF] md:text-12 lg:font-medium`} fontSize={10} lineHeight="loose">
          {subtitle}
        </Typography>
        <Typography className={`mt-18 text-36 text-[#FFFFFF] md:text-52`} medium fontFamily="NunitoSansBold">
          {title}
        </Typography>
        {ctaLabel && (
          <Link link={ctaReference}>
            <Button className="mt-24 bg-[#0E9C00]">
              <Typography fontFamily="BarlowSemiCondensedSemiBold" className="text-16  uppercase md:text-20">
                {ctaLabel}
              </Typography>
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Tile;
