import React, { useCallback, useEffect, useState } from 'react';
import humanNumber from 'human-number';
import Typography from 'components/commercetools-ui/atoms/typography';
import DollarIcon from 'components/icons/dollar';
import { useAccount, useReports } from 'frontastic';
import { filterByQuarter, filterByYear, getAverage, getCurrentQuarterDates, getTotal } from './utils/calculations';
export interface TotalizerCard {
  text: string;
  total: number;
  avg?: number;
  subtitle?: string;
  startDate: Date;
  endDate: Date;
  iconColor: string;
  borderColor: string;
}

export interface Props {
  onTotalizerClick: (startDate: Date, endDate: Date) => void;
  displayInOneRow?: boolean;
  mainCardsIconColor: string;
  mainCardsBorderColor: string;
  quarterCardsIconColor: string;
  quarterCardsBorderColor: string;
  enableComponentActions: boolean;
}

const TotalizerCard: React.FC<Props> = ({
  onTotalizerClick,
  displayInOneRow,
  mainCardsIconColor,
  mainCardsBorderColor,
  quarterCardsIconColor,
  quarterCardsBorderColor,
  enableComponentActions,
}) => {
  const { getReports } = useReports();
  const [reportsData, setReportsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const firstDayOfTheYear = new Date(new Date().getFullYear(), 0, 1);
  const currentDate = new Date();
  const currentYear = new Date().getFullYear();

  const currentYearData = filterByYear(reportsData, currentYear);
  const currentYearTotal = getTotal(currentYearData);
  const currentYearAverage = getAverage(currentYearData);

  const currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3);
  const { firstDayOfTheQuarter, lastDayOfTheQuarter } = getCurrentQuarterDates();
  const currentQuarterData = filterByQuarter(reportsData, currentYear, currentQuarter);
  const currentQuarterTotal = getTotal(currentQuarterData);
  const currentQuarterAverage = getAverage(currentQuarterData);

  const [activeIndex, setActiveIndex] = useState<number | null>(enableComponentActions ? 0 : null);

  const mainTotalizerCards = [
    {
      text: 'Spent this year',
      subtitle: 'Avg. Order value:',
      total: currentYearTotal,
      avg: currentYearAverage,
      startDate: firstDayOfTheYear,
      endDate: currentDate,
      iconColor: mainCardsIconColor,
      borderColor: mainCardsBorderColor,
    },
    {
      text: 'Spent this quarter',
      subtitle: 'Avg. Order value:',
      total: currentQuarterTotal,
      avg: currentQuarterAverage,
      startDate: firstDayOfTheQuarter,
      endDate: lastDayOfTheQuarter,
      iconColor: mainCardsIconColor,
      borderColor: mainCardsBorderColor,
    },
  ];

  const getQuarterData = () => {
    const year = new Date().getFullYear();
    const quarters = [];

    for (let i = 0; i < 4; i++) {
      const startDate = new Date(year, i * 3, 1);
      const endDate = new Date(year, (i + 1) * 3, 0);
      const text = `Q${i + 1}`;
      const quarter = filterByQuarter(reportsData, currentYear, i + 1);
      const total = getTotal(quarter) ?? 0;
      const quarterAvg = getAverage(quarter);
      const avg = isNaN(quarterAvg) ? 0 : quarterAvg;
      const subtitle = 'Avg. Order value:';
      const borderColor = quarterCardsBorderColor;
      const iconColor = quarterCardsIconColor;
      quarters.push({ quarter: i + 1, startDate, endDate, text, total, avg, subtitle, borderColor, iconColor });
    }

    return quarters;
  };

  const fetchReportsData = useCallback(
    async (minDate, maxDate) => {
      setLoading(true);
      const reportsData = await getReports(minDate, maxDate);
      setLoading(false);
      setReportsData(reportsData);
    },
    [getReports],
  );

  const formatNumber = (number: number) => {
    return humanNumber(number, (n: number) => Number.parseFloat(n.toString()).toFixed(2));
  };

  const handleClick = (total: number, startDate: Date, endDate: Date, index: number) => {
    if (total > 0) {
      onTotalizerClick(startDate, endDate);
      setActiveIndex(index);
    }
  };

  const allData = [...mainTotalizerCards, ...getQuarterData()];

  useEffect(() => {
    fetchReportsData(firstDayOfTheYear, currentDate);
  }, [fetchReportsData]);

  const renderDataItem = (dataItem: TotalizerCard, index: number) => (
    <div
      key={index}
      className={`bg-100 flex min-h-[100px] w-full min-w-[200px] flex-col justify-between space-y-4 rounded-md border-l-[8px] px-20 py-20 shadow-md transition-shadow ${
        dataItem.borderColor
      } border-b border-r border-t border-solid ${
        activeIndex === index && enableComponentActions
          ? `border-l-[12px] shadow-inner !${dataItem.borderColor} !bg-slate-100 !shadow-inner shadow-none`
          : ''
      } ${
        dataItem.total !== undefined && dataItem.total > 0
          ? `${enableComponentActions ? 'cursor-pointer hover:shadow-lg' : ''}`
          : 'hidden md:flex md:cursor-not-allowed md:opacity-50'
      }`}
      onClick={
        enableComponentActions
          ? () => handleClick(dataItem.total, dataItem.startDate, dataItem.endDate, index)
          : undefined
      }
    >
      <div className="flex flex-row items-center space-x-2">
        <DollarIcon fillColor={dataItem.iconColor} />
        <Typography fontSize={18} fontFamily="BarlowSemiCondensedRegular" className="pl-10 text-left">
          {dataItem.text}
        </Typography>
      </div>
      <Typography fontSize={32} fontFamily="BarlowSemiCondensedRegular" className="mt-20 py-10 text-left font-black">
        {`$${formatNumber(dataItem?.total)}`}
      </Typography>
      <Typography fontSize={12} fontFamily="BarlowSemiCondensedRegular" className="mt-20 text-left">
        {`${dataItem.subtitle} ${formatNumber(dataItem.avg!)}`}
      </Typography>
    </div>
  );

  const renderDataItems = (data: TotalizerCard[], startIdx: number) =>
    data.map((dataItem, index) => renderDataItem(dataItem, startIdx + index));

  return !displayInOneRow ? (
    <div className="mr-10">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">{renderDataItems(allData.slice(0, 2), 0)}</div>
      <div className="mt-20 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
        {renderDataItems(allData.slice(2), 2)}
      </div>
    </div>
  ) : (
    <div className="mt-10 inline-block w-full overflow-x-auto">
      <div className="flex flex-row gap-4">{renderDataItems(allData, 0)}</div>
    </div>
  );
};

export default TotalizerCard;
