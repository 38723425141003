import React, { useCallback, useEffect, useState } from 'react';
import Chart from 'components/commercetools-ui/organisms/account/sections/dashboard/chart';
import SpentChartBar from 'components/commercetools-ui/organisms/account/sections/dashboard/spentChartBar';
import OrdersByRegion from 'components/commercetools-ui/organisms/account/sections/dashboard/barChart';
import TopProducts from 'components/commercetools-ui/organisms/account/sections/dashboard/table';
import PaymentMethods from 'components/commercetools-ui/organisms/account/sections/dashboard/pieChart';
import TotalizerCards from 'components/commercetools-ui/organisms/account/sections/dashboard/totalizer-card';
import { useReports } from 'frontastic';

type DashboardType =
  | 'spentChart'
  | 'spentChartBar'
  | 'ordersByRegion'
  | 'topProducts'
  | 'paymentMethods'
  | 'totalizerCards';

interface Props {
  data: {
    dashboardType: DashboardType;
    [key: string]: any;
  };
  customerGroup: any;
}

interface ChartConfig {
  component: React.FC<any>;
  props?: {
    reportsData?: any[];
    onDatesSelected?: (startDate: string | Date, endDate: string | Date) => Promise<void>;
    startDate: Date;
    endDate: Date;
    displayInOneRow?: boolean;
    mainCardsIconColor?: string;
    mainCardsBorderColor?: string;
    quarterCardsIconColor?: string;
    quarterCardsBorderColor?: string;
    enableComponentActions?: boolean;
  };
}

const DashboardTastic: React.FC<Props> = ({ data, customerGroup }) => {
  const allowedReports = customerGroup?.obj?.custom?.fields?.allowedReports || [];
  const hasAccessToAll = allowedReports.includes('all');
  const { getReports } = useReports();
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const startDate = new Date(new Date().getFullYear(), 0, 1);
  const currentDate = new Date();
  const [minDateSelected, setMinDateSelected] = useState(startDate);
  const [maxDateSelected, setMaxDateSelected] = useState(currentDate);

  const handleDatesSelected = async (startDate: string | Date, endDate: string | Date) => {
    const minDate = formatDate(startDate);
    const maxDate = formatDate(endDate);

    if (minDate !== undefined && maxDate !== undefined) {
      setLoading(true);
      const reportsData = await getReports(minDate, maxDate);
      setLoading(false);
      setChartData(reportsData);
      setMinDateSelected(minDate);
      setMaxDateSelected(maxDate);
    }
  };

  const fetchReportsData = useCallback(
    async (minDate, maxDate) => {
      setLoading(true);
      const reportsData = await getReports(minDate, maxDate);
      setLoading(false);
      setChartData(reportsData);
    },
    [getReports],
  );

  const formatDate = (date: any) => {
    const isoString = date?.toISOString();
    return isoString?.substring(0, 10);
  };

  useEffect(() => {
    if (allowedReports?.length > 0) fetchReportsData(minDateSelected, currentDate);
  }, [fetchReportsData]);

  const chartConfigurations: Record<DashboardType, ChartConfig> = {
    spentChart: {
      component: Chart,
      props: {
        reportsData: chartData,
        onDatesSelected: handleDatesSelected,
        startDate: minDateSelected,
        endDate: maxDateSelected,
      },
    },
    spentChartBar: {
      component: SpentChartBar,
      props: {
        reportsData: chartData,
        onDatesSelected: handleDatesSelected,
        startDate: minDateSelected,
        endDate: maxDateSelected,
      },
    },
    ordersByRegion: {
      component: OrdersByRegion,
      props: {
        startDate: minDateSelected,
        endDate: maxDateSelected,
      },
    },
    topProducts: {
      component: TopProducts,
      props: {
        startDate: minDateSelected,
        endDate: maxDateSelected,
      },
    },
    paymentMethods: {
      component: PaymentMethods,
      props: {
        startDate: minDateSelected,
        endDate: maxDateSelected,
      },
    },
    totalizerCards: {
      component: TotalizerCards,
      props: {
        startDate: minDateSelected,
        endDate: maxDateSelected,
        displayInOneRow: true,
        enableComponentActions: false,
        mainCardsIconColor: '#85BB65',
        mainCardsBorderColor: 'border-l-[#85BB65]',
        quarterCardsIconColor: '#274082',
        quarterCardsBorderColor: 'border-l-[#274082]',
      },
    },
  };

  const dashboardTypeToReportNameMap: Record<DashboardType, string> = {
    spentChart: 'spent-daily',
    spentChartBar: 'spent-yearly',
    ordersByRegion: 'orders-by-region',
    topProducts: 'orders-top-products',
    paymentMethods: 'payment-methods-all',
    totalizerCards: 'spent-cards',
  };

  const selectedChartConfig = chartConfigurations[data?.dashboardType] || chartConfigurations['spentChart'];
  const { component: SelectedChartComponent, props: selectedChartProps = {} } = selectedChartConfig;

  const reportName = dashboardTypeToReportNameMap[data.dashboardType];
  const isVisible = hasAccessToAll || allowedReports.includes(reportName);

  return isVisible ? <SelectedChartComponent {...selectedChartProps} /> : null;
};

export default DashboardTastic;
