import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
export interface Props {
  onDatesSelected: (startDate: any, endDate: any) => void;
  startDate?: string;
  endDate?: string;
  orientation?: 'horizontal' | 'vertical';
  numberOfMonths?: 1 | 2;
  className?: string;
}

const DatePicker: React.FC<Props> = ({
  onDatesSelected,
  startDate,
  endDate,
  orientation,
  numberOfMonths,
  className,
}) => {
  const [minDate, setMinDate] = useState(moment(startDate, 'YYYY-MM-DD'));
  const [maxDate, setMaxDate] = useState(moment(endDate, 'YYYY-MM-DD'));
  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    setMinDate(moment(startDate, 'YYYY-MM-DD'));
    setMaxDate(moment(endDate, 'YYYY-MM-DD'));
  }, [startDate, endDate]);

  const handleDatesChange = ({ startDate, endDate }: any) => {
    setMinDate(startDate);
    setMaxDate(endDate);
    onDatesSelected(startDate, endDate);
  };

  const handleFocusChange = (focusedInput: any) => {
    setFocusedInput(focusedInput);
  };

  return (
    <div style={{ position: 'relative' }} className={className}>
      <DateRangePicker
        startDate={minDate}
        startDateId="startDateId"
        endDate={maxDate}
        endDateId="endDateId"
        onDatesChange={({ startDate, endDate }) => handleDatesChange({ startDate, endDate })}
        isOutsideRange={() => false}
        focusedInput={focusedInput}
        onFocusChange={handleFocusChange}
        orientation={orientation ?? 'horizontal'}
        numberOfMonths={numberOfMonths ?? 2}
      />
    </div>
  );
};

export default DatePicker;
