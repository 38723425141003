import React, { useCallback, useEffect, useState } from 'react';
import humanNumber from 'human-number';
import { ResponsiveContainer, Sector, PieChart, Pie, Cell, Legend } from 'recharts';
import Overlay from 'components/commercetools-ui/atoms/overlay';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import useViewportWidth from 'helpers/hooks/useViewportWidth';
import { useReports } from 'frontastic';
import DatePicker from './date-picker';

interface Props {
  startDate: any;
  endDate: any;
}

interface OrdersPaymentProps {
  payment_ya_count: number;
  payment_credit_card_count: number;
  payment_post_pay_count: number;
  payment_ya_sum: number;
  payment_credit_card_sum: number;
  payment_post_pay_sum: number;
}

const BarChart: React.FC<Props> = ({ startDate, endDate }) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { isMobile } = useViewportWidth();
  const { getOrdersPayment } = useReports();
  const [ordersPaymentData, setOrdersPaymentData] = useState<OrdersPaymentProps>();
  const [minDateSelected, setMinDateSelected] = useState(new Date(new Date().getFullYear(), 0, 1));
  const [maxDateSelected, setMaxDateSelected] = useState(new Date());
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const formatDate = (date: any) => {
    const isoString = date?.toISOString();
    return isoString?.substring(0, 10);
  };

  const fetchReportsData = useCallback(
    async (minDate, maxDate) => {
      setLoading(true);
      const reportsData = await getOrdersPayment(minDate, maxDate);
      setLoading(false);
      setOrdersPaymentData(reportsData);
    },
    [getOrdersPayment, minDateSelected, maxDateSelected],
  );

  useEffect(() => {
    fetchReportsData(minDateSelected, maxDateSelected);
  }, [fetchReportsData]);

  const handleDatesSelected = async (startDate: string | Date, endDate: string | Date) => {
    const minDate = formatDate(startDate);
    const maxDate = formatDate(endDate);

    if (minDate !== undefined && maxDate !== undefined) {
      setMinDateSelected(minDate);
      setMaxDateSelected(maxDate);
    }
  };

  const formatNumber = (number: number) => {
    return humanNumber(number, (n: number) => Number.parseFloat(n.toString()).toFixed(2));
  };

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
      message,
      shouldFormatNumber,
    } = props;
    const valueMessage = message ? message : '';
    const formattedValue = shouldFormatNumber ? formatNumber(value) : value;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 1;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        {!isMobile && (
          <>
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey}
              textAnchor={textAnchor}
              fill="#333"
            >{`${valueMessage}${formattedValue}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
              {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
          </>
        )}
      </g>
    );
  };

  const renderLegend = (message: string) => {
    return (
      <ul className="recharts-default-legend m-0 flex items-center justify-center p-0">
        <li className="recharts-legend-item legend-item-0 mr-10 inline-block">
          <span className="recharts-legend-item-text font-NunitoSansRegular">{message}</span>
        </li>
      </ul>
    );
  };

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  const dataCount = [
    { name: 'Yearly allowance', value: ordersPaymentData?.payment_ya_count },
    { name: 'Credit card', value: ordersPaymentData?.payment_credit_card_count },
    { name: 'Direct bill', value: ordersPaymentData?.payment_post_pay_count },
  ];

  const dataSum = [
    { name: 'Yearly allowance', value: ordersPaymentData?.payment_ya_sum },
    { name: 'Credit card', value: ordersPaymentData?.payment_credit_card_sum },
    { name: 'Direct bill', value: ordersPaymentData?.payment_post_pay_sum },
  ];

  const renderLegendComplete = (data: { name: string; value?: number }[], unit?: string) => {
    return (
      <ul className="recharts-default-legend m-0 flex-col items-center justify-center pt-10">
        {data?.map((item, index) => (
          <li key={index} className="recharts-legend-item legend-item-0 mr-10 inline-block">
            <span className="recharts-legend-item-text font-NunitoSansRegular">{`${item?.name} - ${
              unit ?? ''
            }${formatNumber(item.value ?? 0)}`}</span>
          </li>
        ))}
      </ul>
    );
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  const transformData = (data: any) => {
    const transformedData = [];
    const paymentMethods: { [key: string]: string } = {
      payment_ya: 'Yearly allowance',
      payment_post_pay: 'Direct bill',
      payment_credit_card: 'Credit Card',
    };

    for (const key in data) {
      if (key.includes('_count')) {
        const method = key.replace('_count', '');
        const paymentMethod = paymentMethods[method];
        transformedData.push({
          paymentMethod,
          quantity: data[key],
          amount: data[`${method}_sum`],
        });
      }
    }

    return transformedData;
  };

  const transformedData = transformData(ordersPaymentData);
  const sortedData = transformedData.sort((a, b) => b.amount - a.amount);

  return (
    <>
      {loading && <Overlay />}
      <div className="relative mt-20 h-fit rounded-lg border border-gray-300 p-8 shadow-md">
        <Typography
          fontFamily="BarlowSemiCondensedRegular"
          className="mt-52 text-left text-28 font-bold lg:mt-0 xl:text-36"
        >
          {formatAccountMessage({
            id: 'payment.method.regions',
            defaultMessage: 'Payment methods used by all regions',
          })}
        </Typography>
        <div className="absolute right-0 top-0 hidden 2xl:block">
          <DatePicker
            onDatesSelected={handleDatesSelected}
            startDate={startDate}
            endDate={endDate}
            orientation="horizontal"
          />
        </div>
        <div className="absolute right-0 top-0 2xl:hidden">
          <DatePicker
            onDatesSelected={handleDatesSelected}
            startDate={startDate}
            endDate={endDate}
            orientation="vertical"
            numberOfMonths={1}
          />
        </div>
        <div className="mt-20 flex flex-col gap-32">
          <div className="w-full overflow-hidden">
            <table className="w-full table-auto">
              <thead>
                <tr className="sticky top-0 bg-gray-200">
                  <th className="px-4 py-2 text-20">
                    {formatAccountMessage({ id: 'payment.method', defaultMessage: 'Payment Method' })}
                  </th>
                  <th className="px-4 py-2 text-20">
                    {formatAccountMessage({ id: 'quantity', defaultMessage: 'Quantity' })}
                  </th>
                  <th className="px-4 py-2 text-20">
                    {formatAccountMessage({ id: 'amount', defaultMessage: 'Amount' })}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {sortedData.map((row, index) => (
                  <tr key={index} className="text-center">
                    <td className="border px-4 py-2 font-NunitoSansRegular">{row.paymentMethod}</td>
                    <td className="border px-4 py-2 font-NunitoSansRegular">{row.quantity}</td>
                    <td className="border px-4 py-2 font-NunitoSansRegular">${formatNumber(row.amount)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="grid w-full grid-cols-1 xl:grid-cols-2">
            <div className="mb-4">
              <ResponsiveContainer width="100%" height={350}>
                <PieChart width={350} height={350}>
                  <Pie
                    activeIndex={activeIndex}
                    activeShape={(props: any) => renderActiveShape({ ...props, shouldFormatNumber: true })}
                    data={dataCount}
                    cx="50%"
                    cy="50%"
                    innerRadius={65}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                  >
                    {dataCount.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Legend content={isMobile ? renderLegendComplete(dataCount) : renderLegend('Payments Qty.')} />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div>
              <ResponsiveContainer width="100%" height={350}>
                <PieChart width={350} height={350}>
                  <Pie
                    activeIndex={activeIndex}
                    activeShape={(props: any) =>
                      renderActiveShape({ ...props, message: '$', shouldFormatNumber: true })
                    }
                    data={dataSum}
                    cx="50%"
                    cy="50%"
                    innerRadius={65}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                  >
                    {dataSum.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Legend content={isMobile ? renderLegendComplete(dataSum, '$') : renderLegend('Payments amount')} />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BarChart;
