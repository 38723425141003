import React from 'react';
import Tile, { TileProps } from 'components/commercetools-ui/organisms/content/tile';
import Card, { CardProps } from './card';
import Minicard, { MiniCard as MiniCardProps } from './mini-card';
import TextTile, { TextTileProps } from './text-tile';
import Title, { Title as TitleProps } from './title';

export interface EcoSectionProps {
  miniCards: MiniCardProps[];
  titleInfo: TitleProps;
  tileInfo: TileProps;
  textTileInfo?: TextTileProps;
}

const EcoSection: React.FC<EcoSectionProps> = ({ miniCards, titleInfo, tileInfo, textTileInfo }) => {
  const miniCardsComp = () => {
    return miniCards.map((miniCard, index) => {
      return <Minicard key={index} miniCard={miniCard} />;
    });
  };

  return (
    <>
      <div>
        <div className="flex flex-col items-center">
          <Title title={titleInfo}></Title>
          <div className="flex flex-col items-end space-x-30 md:flex-row md:items-center">{miniCardsComp()}</div>
        </div>
        {tileInfo && (
          <Tile
            image={tileInfo?.image}
            title={tileInfo?.title}
            subtitle={tileInfo?.subtitle}
            ctaLabel={tileInfo?.ctaLabel}
            ctaReference={tileInfo?.ctaReference}
            className="mt-52 h-[296px] font-NunitoSansBold text-52 md:h-[532px] lg:h-[668px]"
            alignment="left"
          />
        )}
        {textTileInfo?.icon && (
          <TextTile
            icon={textTileInfo.icon}
            mainText={textTileInfo.mainText}
            ctaText={textTileInfo.ctaText!}
            resultsEco={textTileInfo.resultsEco}
            tileContent={textTileInfo.tileContent}
            ctaReference={textTileInfo.ctaReference}
          />
        )}
      </div>
    </>
  );
};

export default EcoSection;
