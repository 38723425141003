import React from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';

const StyleGuide: React.FC = () => {
  return (
    <div className="container">
      <div className="about-page">
        <div className="my-32 flex-col gap-52 lg:grid lg:grid-cols-8">
          <div className="mb-20 lg:hidden">
            <Typography fontFamily="NunitoSansBold" className="text-32">
              UNIFORM STYLE GUIDE
            </Typography>
          </div>
          <div className="col-span-6 col-start-2 w-full">
            <div className="flex justify-between">
              <Typography fontFamily="NunitoSansBold" className="hidden pt-40 text-32 lg:flex">
                UNIFORM STYLE GUIDE
              </Typography>
            </div>
            <div className="my-40 w-full">
              <Typography fontFamily="NunitoSansBold" className="pb-32 text-28">
                REPRESENTING CHIPOTLE
              </Typography>

              <Typography fontFamily="NunitoSansSemiBold" className="text-18">
                We want you to have fun and show off your personality, but we also expect you to be presentable. Your
                appearance should not detract from our customers’ overall experience. So, when you are working, we
                expect your clothes to be clean, with no visible holes, rips, tears or stains, and for you to follow the
                guidelines below:
              </Typography>

              <div className="my-32 grid grid-cols-1 gap-32 lg:grid-cols-6">
                <Typography fontFamily="NunitoSansBold" className="col-span-1 text-16" id="howToMeassure">
                  HAT:
                </Typography>
                <div className="col-span-5 flex-col">
                  <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
                    for Crew: Chipotle uniform hat only at all times (see Hat Guide at end of document)
                  </Typography>
                  <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
                    for Managers: Chipotle uniform hat only when working with food
                  </Typography>
                  <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
                    Bandanas of any type cannot take the place of hats
                  </Typography>
                </div>
              </div>

              <div className="my-32 grid grid-cols-1 gap-32 lg:grid-cols-6">
                <Typography fontFamily="NunitoSansBold" className="col-span-1 text-16" id="howToMeassure">
                  T-SHIRTS:
                </Typography>
                <div className="col-span-5 flex-col">
                  <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
                    for Crew: Chipotle Uniform t-shirt (no promo shirts, see Tee Shirt Guide)
                  </Typography>
                  <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
                    for Managers: Chipotle Manager Uniform polo, button down or henley
                  </Typography>
                </div>
              </div>

              <div className="my-32 grid grid-cols-1 gap-32 lg:grid-cols-6">
                <Typography fontFamily="NunitoSansBold" className="col-span-1 text-16" id="howToMeassure">
                  PANTS:
                </Typography>
                <div className="col-span-5 flex-col">
                  <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
                    Jeans or Pants
                  </Typography>
                  <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
                    Jeans or Pants should not have any rips or holes and should be worn no lower than 3 inches below the
                    natural waist
                  </Typography>
                </div>
              </div>

              <div className="my-32 grid grid-cols-1 gap-32 lg:grid-cols-6">
                <Typography fontFamily="NunitoSansBold" className="col-span-1 text-16" id="howToMeassure">
                  BLANK BLACK APRON:
                </Typography>
                <div className="col-span-5 flex-col">
                  <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
                    for Crew: Blank Black Chipotle apron only at all times (see Apron Guide at end of document)
                  </Typography>
                  <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
                    for Managers: Blank Black Chipotle apron only necessary when working with food
                  </Typography>
                </div>
              </div>

              <div className="my-32 grid grid-cols-1 gap-32 lg:grid-cols-6">
                <Typography fontFamily="NunitoSansBold" className="col-span-1 text-16" id="howToMeassure">
                  SHOES:
                </Typography>
                <div className="col-span-5 flex-col">
                  <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
                    Shoes for Crews (shoes or CrewGuard overshoes) or other approved slip resistant shoes
                  </Typography>
                </div>
              </div>

              <div className="my-32 grid grid-cols-1 gap-32 lg:grid-cols-6">
                <Typography fontFamily="NunitoSansBold" className="col-span-1 text-16" id="howToMeassure">
                  CORPORATE GEAR:
                </Typography>
                <div className="col-span-5 flex-col">
                  <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
                    Items from the Chipotle Corporate Gear Collection are not to be worn when working in the
                    restaurants. Only the items in the Uniforms section are to be worn when working.
                  </Typography>
                </div>
              </div>
              <div className="my-32 grid grid-cols-1 gap-32 lg:grid-cols-6">
                <Typography fontFamily="NunitoSansBold" className="col-span-1 text-16" id="howToMeassure">
                  COLD WEATHER:
                </Typography>
                <div className="col-span-5  flex-col">
                  <Typography fontFamily="NunitoSansSemiBold" className="text-16" id="howToMeassure">
                    Any shirt worn under the approved uniform shirt must be black and sleeves are to be fitted.
                  </Typography>
                </div>
              </div>
            </div>

            <div className="my-50 w-full flex-col gap-52 pt-20 lg:grid lg:grid-cols-2">
              <div className="w-fit">
                <img
                  className=""
                  src="/images/crew-uniforms.png"
                  title="Philosophy badge.png"
                  alt="Philosophy badge.png"
                />
              </div>
              <div className="w-fit">
                <img
                  className="mx-auto"
                  src="/images/crew-graphics-real.png"
                  title="Philosophy badge.png"
                  alt="Philosophy badge.png"
                />
              </div>
              <div className="w-fit">
                <img
                  className="mx-auto"
                  src="/images/crew-graphics-loyalty.png"
                  title="Philosophy badge.png"
                  alt="Philosophy badge.png"
                />
              </div>
              <div className="w-fit">
                <img
                  className="mx-auto"
                  src="/images/crew-graphics-retired.png"
                  title="Philosophy badge.png"
                  alt="Philosophy badge.png"
                />
              </div>

              <div className="w-fit">
                <img
                  className="mx-auto"
                  src="/images/chipotle-uniform-forbidden.png"
                  title="Philosophy badge.png"
                  alt="Philosophy badge.png"
                />
              </div>
              <div className="w-fit">
                <img
                  className="mx-auto"
                  src="/images/crew-maternity.png"
                  title="Philosophy badge.png"
                  alt="Philosophy badge.png"
                />
              </div>
              <div className="w-fit">
                <img
                  className="mx-auto"
                  src="/images/manager-polos.png"
                  title="Philosophy badge.png"
                  alt="Philosophy badge.png"
                />
              </div>
              <div className="w-fit">
                <img
                  className="mx-auto"
                  src="/images/crew-kitchen.png"
                  title="Philosophy badge.png"
                  alt="Philosophy badge.png"
                />
              </div>
              <div className="w-fit">
                <img
                  className="mx-auto"
                  src="/images/restauranter-polos.png"
                  title="Philosophy badge.png"
                  alt="Philosophy badge.png"
                />
              </div>
              <div className="w-fit">
                <img
                  className="mx-auto"
                  src="/images/field-leader-polos.png"
                  title="Philosophy badge.png"
                  alt="Philosophy badge.png"
                />
              </div>
              <div className="w-fit">
                <img
                  className="mx-auto"
                  src="/images/field-leader-shirting.png"
                  title="Philosophy badge.png"
                  alt="Philosophy badge.png"
                />
              </div>
              <div className="w-fit">
                <img
                  className="mx-auto"
                  src="/images/crew-apron.png"
                  title="Philosophy badge.png"
                  alt="Philosophy badge.png"
                />
              </div>
              <div className="w-fit">
                <img
                  className="mx-auto"
                  src="/images/crew-hats.png"
                  title="Philosophy badge.png"
                  alt="Philosophy badge.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StyleGuide;
