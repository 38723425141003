import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Typography from 'components/commercetools-ui/atoms/typography';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { useReports } from 'frontastic';
import DatePicker from './date-picker';

interface Props {
  startDate: any;
  endDate: any;
}

interface ProductData {
  item_description: string;
  product_shipped_total: number;
  product_gross_total: number;
}

const Table: React.FC<Props> = ({ startDate, endDate }) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { locale } = useRouter();
  const { getOrdersRegion, getOrdersTopProducts } = useReports();
  const [topProductsData, setTopProductsData] = useState<ProductData[]>([]);
  const [minDateSelected, setMinDateSelected] = useState(new Date(new Date().getFullYear(), 0, 1));
  const [maxDateSelected, setMaxDateSelected] = useState(new Date());

  const formatDate = (date: any) => {
    const isoString = date?.toISOString();
    return isoString?.substring(0, 10);
  };

  const fetchReportsData = useCallback(
    async (minDate, maxDate) => {
      const topProducts = await getOrdersTopProducts(minDate, maxDate);
      setTopProductsData(topProducts);
    },
    [getOrdersRegion, minDateSelected, maxDateSelected],
  );

  useEffect(() => {
    fetchReportsData(minDateSelected, maxDateSelected);
  }, [fetchReportsData]);

  const handleDatesSelected = async (startDate: string | Date, endDate: string | Date) => {
    const minDate = formatDate(startDate);
    const maxDate = formatDate(endDate);

    if (minDate !== undefined && maxDate !== undefined) {
      setMinDateSelected(minDate);
      setMaxDateSelected(maxDate);
    }
  };

  return (
    <div className="relative mt-20 h-fit rounded-lg border border-gray-300 shadow-md">
      <Typography
        fontFamily="BarlowSemiCondensedRegular"
        className="my-20 ml-8 mt-52 text-left text-28 font-bold sm:mt-0 xl:text-36"
      >
        {formatAccountMessage({ id: 'top.products', defaultMessage: 'Top Products' })}
      </Typography>
      <div className="absolute right-0 top-0 hidden 2xl:block">
        <DatePicker
          onDatesSelected={handleDatesSelected}
          startDate={startDate}
          endDate={endDate}
          orientation="horizontal"
        />
      </div>
      <div className="absolute right-0 top-0 2xl:hidden">
        <DatePicker
          onDatesSelected={handleDatesSelected}
          startDate={startDate}
          endDate={endDate}
          orientation="vertical"
          numberOfMonths={1}
        />
      </div>
      <div className="h-[32rem] overflow-y-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="sticky top-0 bg-gray-200">
              <th className="px-4 py-2 text-20">
                {formatAccountMessage({ id: 'products', defaultMessage: 'Products' })}
              </th>
              <th className="px-4 py-2 text-20">
                {formatAccountMessage({ id: 'quantity', defaultMessage: 'Quantity' })}
              </th>
              <th className="px-4 py-2 text-20">{formatAccountMessage({ id: 'amount', defaultMessage: 'Amount' })}</th>
            </tr>
          </thead>
          <tbody>
            {topProductsData.map((item, index) => (
              <tr key={index} className="text-center">
                <td className="border px-4 py-2 font-NunitoSansRegular">{item.item_description}</td>
                <td className="border px-4 py-2 font-NunitoSansRegular">{item.product_shipped_total}</td>
                <td className="border px-4 py-2 font-NunitoSansRegular">
                  {CurrencyHelpers.formatForCurrency(item.product_gross_total * 100, locale)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
