export const filterByYear = (data: any[], year: number) => {
  return data.filter((item) => {
    const itemYear = new Date(item.date).getFullYear();
    item.isCurrentYear = itemYear === year;
    return itemYear === year;
  });
};

// Function to filter data by quarter and set a flag for current quarter
export const filterByQuarter = (data: any[], year: number, quarter: number) => {
  return data.filter((item) => {
    const itemYear = new Date(item.date).getFullYear();
    const itemQuarter = Math.ceil((new Date(item.date).getMonth() + 1) / 3);
    item.isCurrentQuarter = itemYear === year && itemQuarter === quarter;
    return itemYear === year && itemQuarter === quarter;
  });
};

export const getCurrentQuarterDates = () => {
  const today = new Date();
  const quarter = Math.floor((today.getMonth() + 3) / 3); // Determine current quarter

  // Calculate first day of the quarter
  const firstDayOfTheQuarter = new Date(today.getFullYear(), (quarter - 1) * 3, 1);

  // Calculate last day of the quarter
  const lastDayOfTheQuarter = new Date(today.getFullYear(), quarter * 3, 0);

  return { firstDayOfTheQuarter, lastDayOfTheQuarter };
};

// Function to calculate total
export const getTotal = (data: any[]) => {
  return data.reduce((acc, item) => acc + item.total_invoiced, 0) as any;
};

// Function to calculate average
export const getAverage = (data: any[]) => {
  if (!data) return 0;
  const total = getTotal(data);
  return total / data.length;
};
